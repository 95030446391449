import { JobComponents, SchemaJob } from "./types";

export const defaultJobComponent: JobComponents = {
  show: false,
};

export const defaultJobSchema: SchemaJob = {
  route: "",
  value: "",
  description: "",
  jobStatusText: "",
  buttonText: "",
  components: {
    builderSelector: { ...defaultJobComponent },
    builderReadOnly: { ...defaultJobComponent },
    connectorSelector: { ...defaultJobComponent },
    querySelector: { ...defaultJobComponent },
    scheduleSelector: { ...defaultJobComponent },
    savedJobsList: { ...defaultJobComponent },
    jobNameInput: { ...defaultJobComponent },
    jobNameReadonly: { ...defaultJobComponent },
    ignoreErrors: { ...defaultJobComponent },
  },
};
