import { get, mapKeys } from "lodash";
import {
  AuthArrayResponse,
  AuthPermission,
  AuthSingleResponse,
  Role,
  UserPermissions,
} from "../../types";
import { AUTH_URL } from "../constants";
import { getApi } from "../methods";

interface HasFullPermissionsOptions {
  service: string;
  token: string;
}

export const getRoles = async ({
  token,
  params,
  options,
}: {
  token: string;
  params?: object;
  options?: object;
}): Promise<AuthArrayResponse<Role>> => {
  const url = `${AUTH_URL}/roles`;
  const filters = get(params, "filter", {});
  const parameters = mapKeys(filters, (_, key) => `filter[${key}]`);
  return getApi<AuthArrayResponse<Role>>({
    url,
    token,
    params: parameters,
    options,
  });
};

const getUserPermissions = async ({ token }: { token: string }) => {
  return getApi<AuthSingleResponse<UserPermissions>>({
    url: `${import.meta.env.VITE_AUTH0_URL}/permissions`,
    token,
  })
    .then((response) => response.data?.attributes?.permissions || [])
    .catch(() => {
      throw new Error("Unable to retrieve permissions");
    });
};

// Checks to see if the user has a "*.*" permissions for the given service
export const hasFullPermissions = async ({
  service,
  token,
}: HasFullPermissionsOptions) => {
  try {
    const permissions = await getUserPermissions({ token });

    // Loop through the permissions looking for the requested service, and then for the *.* action
    return permissions.some((permission: AuthPermission) => {
      if (permission.service === service) {
        return permission.actions.some((action) => action === "*.*");
      }
      return false;
    });
  } catch (e) {
    return false;
  }
};
