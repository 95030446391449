import axios from "axios";
import { sortBy } from "lodash";
import { AuthPermission, Builder, Data } from "../../types";
import { getAuthUrl } from "./utils";

interface BuilderOptions {
  url?: string;
  token: string;
  builders?: Data<Builder>[];
}
export const getBuilders = async ({
  url = `${getAuthUrl()}/builders`,
  token,
  builders = [],
}: BuilderOptions): Promise<Data<Builder>[]> => {
  if (!token) throw new Error("getBuilders needs a token");
  const res = await axios.get(url, {
    timeout: 2000,
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const next = res?.data?.links?.next;
  const newBuilders = [...builders, ...(res?.data?.data || [])];

  if (!next)
    return sortBy(newBuilders, (b: Data<Builder>) => b?.attributes?.name);

  return getBuilders({
    // if next get next
    token,
    url: next,
    builders: newBuilders,
  });
};

interface GetUserPermissionsOptions {
  token: string;
}
const getUserPermissions = async ({ token }: GetUserPermissionsOptions) => {
  return axios
    .get(`${getAuthUrl()}/permissions`, {
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data?.data?.attributes?.permissions || [])
    .catch(() => {
      throw new Error("Unable to retreive permissions");
    });
};

export interface HasFullPermissionsOptions {
  service: string;
  token: string;
}
// Checks to see if the user has a "*.*" permissions for the service
export const hasFullPermissions = async ({
  service,
  token,
}: HasFullPermissionsOptions) => {
  try {
    const permissions = await getUserPermissions({ token });

    // Loop through the permissions looking for the requested service, and then for the *.* action
    return permissions.some((permission: AuthPermission) => {
      if (permission.service === service) {
        return permission.actions.some((action) => action === "*.*");
      }
      return false;
    });
  } catch (e) {
    return false;
  }
};
