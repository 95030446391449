import { AxiosResponse } from "axios";
import { AddPatch } from "fhir-kit-client/types/externals";
import type { Organization } from "fhir/r4";
import { max } from "lodash";
import {
  CommonwellOrganizationFormProps,
  CommonwellResponse,
} from "../../components/BuilderProvisioning/types";
import { MEDICAL_HISTORY_URL } from "../constants";
import { postApi } from "../methods";
import { getOrganizations, patchOrganization } from "../ods/organizations";

const envIdentifier: {
  dev: "0.";
  sandbox: "1.";
  prod: "2.";
  "phi-test": "3.";
} = {
  dev: "0.",
  sandbox: "1.",
  prod: "2.",
  "phi-test": "3.",
};

const determineIdentifierSuffix = () => {
  switch (import.meta.env.VITE_MEDICATION_HISTORY_URL) {
    case "https://api.zusapi.com/medical-history":
      return envIdentifier.prod;
    case "https://api.phi-test.zusapi.com/medical-history":
      return envIdentifier["phi-test"];
    case "https://api.sandbox.zusapi.com/medical-history":
      return envIdentifier.sandbox;
    case "https://api.dev.zusapi.com/medical-history":
    default:
      return envIdentifier.dev;
  }
};

const BASE_COMMONWELL_IDENTIFIER = "2.16.840.1.113883.3.9206.";
const COMMONWELL_IDENTIFIER =
  BASE_COMMONWELL_IDENTIFIER + determineIdentifierSuffix();

const getOrganizationIdentifier = async (token: string) => {
  const organizations = await getOrganizations({
    token,
    params: { identifier: "https://commonwellalliance.org/|" },
  });
  return max(
    organizations.entry
      .map((entry: { resource: Organization }) => {
        const foundIdentifier = entry.resource.identifier?.find((id) =>
          id?.value?.startsWith(COMMONWELL_IDENTIFIER)
        );
        if (foundIdentifier?.value) {
          return parseInt(
            foundIdentifier.value.split(COMMONWELL_IDENTIFIER)[1],
            10
          );
        }
        return undefined;
      })
      .filter(Boolean)
  ) as number;
};

export const updateODSOrganization = ({
  token,
  builderId,
  odsOrganizationId,
  commonwellId,
}: {
  token: string;
  builderId: string;
  odsOrganizationId: string;
  commonwellId: string;
}) => {
  const patchBody: AddPatch[] = [
    {
      op: "add",
      path: "/identifier/-",
      value: [
        {
          use: "usual",
          system: commonwellId,
          value: builderId,
        },
        {
          use: "usual",
          system: "urn:ietf:rfc:3986",
          value: commonwellId,
        },
        {
          use: "secondary",
          system: "https://commonwellalliance.org/",
          value: commonwellId.split("urn:oid:")[1],
        },
      ],
    },
  ];
  return patchOrganization({
    token,
    body: patchBody,
    resourceId: odsOrganizationId,
    builderId,
  });
};

export const provisionOrganization = async ({
  token,
  data,
  builderId,
  name,
}: {
  token: string;
  data: CommonwellOrganizationFormProps;
  builderId: string;
  odsOrganizationId: string;
  name: string;
}) => {
  const url = `${MEDICAL_HISTORY_URL}/organization`;
  let identifier = await getOrganizationIdentifier(token);
  let commonwellResponse;
  while (commonwellResponse?.status !== 200) {
    try {
      identifier += 1;
      const body = {
        ...data,
        organizationId: COMMONWELL_IDENTIFIER + identifier,
        name,
      };
      // eslint-disable-next-line no-await-in-loop
      commonwellResponse = await postApi<AxiosResponse<CommonwellResponse>>({
        url,
        data: body,
        token,
        options: {
          "practitioner-role": "Administrator",
          "practitioner-name": body.technicalContacts[0].name,
          "organization-name": name,
          "zus-account": builderId,
        },
      });
      return commonwellResponse.data;
    } catch (err: any) {
      if (err.response.status !== 409) throw err;
    }
  }
  return commonwellResponse?.data as CommonwellResponse;
};
