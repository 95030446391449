import { useAuth0 } from "@auth0/auth0-react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { Avatar, Box, Button, Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { getApi } from "../api";
import { Builder, Data, Query } from "../types";

export interface QuerySelectorProps {
  setSelectedQueries: (query: Query[]) => void;
  selectedQueries: Query[];
  builder: Data<Builder> | undefined;
  editingJob?: boolean;
  disabled?: boolean;
}

export const QuerySelector = ({
  setSelectedQueries,
  selectedQueries,
  builder,
  editingJob = false,
  disabled = false,
}: QuerySelectorProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const [queries, setQueries] = useState<Query[]>([]);
  const [builderHasNone, setBuilderHasNone] = useState<boolean>(false);

  useEffect(() => {
    if (builder?.id) {
      // clear out q's on builder change
      setQueries([]);
      // We can ignore this like while editing, because we're locked to a specific builder.
      if (editingJob === false) {
        setSelectedQueries([]);
      }
      setBuilderHasNone(false);

      getAccessTokenSilently().then((token) =>
        getApi({
          url: `/query/${builder.id}`,
          token,
        })
          .then((qs: any) => qs?.data)
          .then((qs: Query[]) => {
            if (qs?.length === 0) {
              setBuilderHasNone(true);
            }
            setQueries(qs);
            if (editingJob === false) {
              setSelectedQueries(qs);
            }
          })
      );
    }
  }, [builder]);

  const isSelected = (query: Query): boolean => {
    return selectedQueries.some(
      (q) => q.queryKey === query.queryKey && query.builderId === q.builderId
    );
  };

  const handleClick = (query: Query) => {
    const selected = isSelected(query);

    let newQueries: Query[] = [];
    if (selected) {
      newQueries = selectedQueries.filter((q) => q.queryKey !== query.queryKey);
    } else {
      newQueries = [...selectedQueries, query];
    }
    setSelectedQueries(newQueries);
  };

  if (queries.length === 0 && builderHasNone) {
    return <i>Builder has no Resources!</i>;
  }

  // EMPTY VIEW
  if (queries.length === 0) {
    return <i>Select a builder to populate Queries</i>;
  }

  const handleSelectClick = (selectAll = true) => {
    let newQueries: Query[] = [];

    if (selectAll) {
      newQueries = [...queries];
    } else {
      newQueries = [];
    }
    setSelectedQueries(newQueries);
  };
  return (
    <>
      <Box
        sx={{
          marginBottom: 1,
          paddingRight: 1,
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          onClick={() => handleSelectClick(true)}
          size="small"
          disabled={selectedQueries?.length === queries?.length || disabled}
          startIcon={<LibraryAddCheckIcon fontSize="small" />}
        >
          Select All
        </Button>
        <Button
          onClick={() => handleSelectClick(false)}
          size="small"
          disabled={selectedQueries?.length === 0 || disabled}
          startIcon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        >
          Deselect All
        </Button>
      </Box>

      {queries.map((query) => {
        return (
          <Chip
            disabled={disabled}
            avatar={
              query.builderId === "zus-base" ? (
                <Avatar
                  sx={{ bgcolor: "white" }}
                  alt="Zus"
                  src="/logo/zus-icon.png"
                />
              ) : undefined
            }
            key={`${query.name}-${query.queryKey}`}
            sx={{ margin: "3px" }}
            onClick={() => handleClick(query)}
            label={query.name}
            color={
              selectedQueries.some((q) => q.queryKey === query.queryKey)
                ? "info"
                : "default"
            }
          />
        );
      })}
    </>
  );
};
