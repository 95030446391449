import { useAuth0 } from "@auth0/auth0-react";
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getBuilders } from "../api/auth/builders";
import { defaultBuilder } from "../default-objects";
import { Builder, Data } from "../types/auth";

interface BuilderSelectorProps {
  builder: Data<Builder> | undefined;
  setBuilder: (builder: Data<Builder>) => void;
}

export const BuilderSelector = ({
  builder,
  setBuilder,
}: BuilderSelectorProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [builders, setBuilders] = useState<Data<Builder>[]>([]);
  const handleBuilderOnChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: Data<Builder> | null
  ) => setBuilder(value ?? defaultBuilder);

  useEffect(() => {
    getAccessTokenSilently().then((token) =>
      getBuilders({ token }).then((bs: Data<Builder>[]) => setBuilders(bs))
    );
  }, []);

  return (
    <Autocomplete
      options={builders}
      getOptionLabel={(option: Data<Builder>) => option.attributes.name}
      id="builder-selector"
      sx={{ width: 300 }}
      value={builder ?? null}
      onChange={(e, v) => handleBuilderOnChange(e, v)}
      renderInput={(params) => (
        <TextField {...params} focused label="Select Builder" />
      )}
    />
  );
};
