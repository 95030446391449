export const getAuthUrl = (): string => {
  switch (window.location.hostname) {
    case "data-pipeline-v2.sandbox.zusapi.com": // SandBox
      return "https://api.sandbox.zusapi.com/auth";
    case "data-pipeline-v2.zusapi.com": // Production
      return "https://api.zusapi.com/auth";
    case "localhost":
    case "data-pipeline-v2.dev.zusapi.com": // DEV
    default:
      return "https://api.dev.zusapi.com/auth"; // Dev as default
  }
};
