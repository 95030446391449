import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getApi } from "../api";

export interface JobStats {
  resourceName: string;
  rectangularization: number | null;
  output: number | null;
  odsFetch?: number | null;
  odsFetchFinal?: number | null;
}

export const JobRunDetailPage = () => {
  const params = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [details, setDetails] = useState({});
  const [stats, setStats] = useState<JobStats[]>([]);
  const [filteredStats, setFilteredStats] = useState<JobStats[]>([]);

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      getApi({ url: `/job/${params.builderId}/${params.jobId}`, token }).then(
        (resp: any) => setDetails(resp?.data)
      );
      getApi({
        url: `/job/stats/${params.jobId}`,
        token,
      }).then((res: any) => {
        setStats(res?.data || []);
        setFilteredStats(res?.data || []);
      });
    });
  }, []);

  const handleInputChange = debounce((value) => {
    const newStats = stats.filter((s) =>
      s.resourceName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredStats([...newStats]);
  }, 150);

  return (
    <Box>
      <h1>Job Stats:</h1>
      <TextField
        id="standard-basic"
        label="filter"
        variant="standard"
        onChange={(e) => handleInputChange(e.target.value)}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Resource</TableCell>
              <TableCell>ODS Initial Resource Count</TableCell>
              <TableCell>ODS Final Resource Count</TableCell>
              <TableCell>Rectangularization</TableCell>
              <TableCell>Connector Output</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredStats &&
              filteredStats.map((row) => (
                <TableRow
                  key={row.resourceName}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.resourceName}
                  </TableCell>
                  <TableCell align="center">{row.odsFetch}</TableCell>
                  <TableCell align="center">{row.odsFetchFinal}</TableCell>
                  <TableCell align="center">{row.rectangularization}</TableCell>
                  <TableCell align="center">{row.output}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <h1>Job Details:</h1>
      <pre>{JSON.stringify(details, null, 2)}</pre>
    </Box>
  );
};
