import { Paper, Stack, Typography } from "@mui/material";
import type { Organization } from "fhir/r4";
import { CommonwellSection } from "./commonwell/CommonwellSection";

interface OperationsSectionProps {
  builderId: string;
  name: string;
  organization: Organization;
}

export const OperationsSection = ({
  builderId,
  name,
  organization,
}: OperationsSectionProps) => {
  return (
    <Paper sx={{ p: "1em" }} elevation={2}>
      <Typography variant="h5">Operations</Typography>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="stretch"
        spacing={2}
      >
        <CommonwellSection
          builderId={builderId}
          name={name}
          organization={organization}
        />
        <h3>Seed sample data</h3>
        <p>TBD</p>
      </Stack>
    </Paper>
  );
};
