import { useAuth0 } from "@auth0/auth0-react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { FhirResource } from "fhir-kit-client";
import type { Address } from "fhir/r4";
import { cloneDeepWith } from "lodash";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { provisionOrganization, updateODSOrganization } from "../../../api";
import { queryClient } from "../../../api/client";
import { SubmissionProgressInfo } from "../SubmissionProgressInfo";
import { CommonwellOrganizationFormProps, CommonwellResponse } from "../types";
import { ArrayFormInput } from "./ArrayFormInput";

enum CommonwellOrganizationEnum {
  TESTING = "Testing",
  ACUTE_CARE = "Acute Care",
  AMBULATORY = "Ambulatory",
  HOSPITAL = "Hospital",
  LAB_SYSTEMS = "Lab Systems",
  PHARMACY = "Pharmacy",
  POST_ACUTE_CARE = "Post Acute Care",
}

interface CommonwellCreationFormProps {
  open: boolean;
  handleClose: () => void;
  builderId: string;
  odsOrganizationId: string;
  name: string;
  location?: Address;
}

export const CommonwellCreationForm = ({
  open,
  handleClose,
  builderId,
  odsOrganizationId,
  name,
  location,
}: CommonwellCreationFormProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [commonwellIdentifierID, setCommonwellIdentifierID] =
    useState<string>("");
  const {
    handleSubmit,
    control,
    getValues,
    formState: { isSubmitting },
  } = useForm<CommonwellOrganizationFormProps>({
    defaultValues: {
      type: "",
      locations: [
        {
          address1: location?.line?.toString(),
          city: location?.city,
          state: location?.state,
          postalCode: location?.postalCode,
          country: location?.country || "USA",
        },
      ],
      technicalContacts: [
        {
          name: "Zus Support",
          title: "Administrator",
          email: "support@zushealth.com",
          phone: "720-555-2222",
        },
      ],
    },
  });

  const {
    mutate: updateOrganization,
    status: odsOrganizationStatus,
    error: odsOrganizationError,
    isIdle: isOdsOrganizationIdle,
  } = useMutation<FhirResource, Error, string>(
    async (commonwellId: string) => {
      const token = await getAccessTokenSilently();
      return updateODSOrganization({
        token,
        builderId,
        odsOrganizationId,
        commonwellId,
      });
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["organization", builderId]),
    }
  );

  const {
    mutate: createCommonwellOrganization,
    status: commonwellProvisionStatus,
    error: commonwellProvisionError,
    isIdle: isCommonwellProvisionIdle,
  } = useMutation<CommonwellResponse, Error, CommonwellOrganizationFormProps>(
    async (data: CommonwellOrganizationFormProps) => {
      const token = await getAccessTokenSilently();
      return provisionOrganization({
        token,
        data,
        builderId,
        odsOrganizationId,
        name,
      });
    },
    {
      onSuccess: (response: CommonwellResponse) => {
        setCommonwellIdentifierID(response.id);
        updateOrganization(response.id);
      },
    }
  );

  const preSubmit = () => {
    const trimmedFormValues = cloneDeepWith(getValues(), (p) =>
      typeof p === "string" ? p.trim() : undefined
    );
    createCommonwellOrganization(trimmedFormValues);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogTitle>Provisioning {name}</DialogTitle>
      <DialogContent sx={{ margin: "1em" }}>
        {!isCommonwellProvisionIdle ? (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <SubmissionProgressInfo
                type="create"
                entity="Commonwell Organization"
                state={commonwellProvisionStatus}
                entityId={commonwellIdentifierID}
                errorMessage={commonwellProvisionError?.message}
              />
              {!isOdsOrganizationIdle && (
                <SubmissionProgressInfo
                  type="update"
                  entity="Organization"
                  state={odsOrganizationStatus}
                  entityId={odsOrganizationId}
                  errorMessage={odsOrganizationError?.message}
                />
              )}
            </Box>
            {!["loading", "idle"].includes(odsOrganizationStatus) && (
              <Button
                variant="contained"
                sx={{ mt: "1em", alignSelf: "flex-end" }}
                onClick={handleClose}
              >
                Confirm
              </Button>
            )}
          </Box>
        ) : (
          <form
            style={{ display: "flex", flexDirection: "column" }}
            onSubmit={handleSubmit(preSubmit)}
          >
            <Paper elevation={6}>
              <Stack
                spacing={3}
                sx={{
                  padding: "1em",
                  minWidth: "520px",
                }}
              >
                <Controller
                  name="type"
                  control={control}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <Autocomplete
                      options={Object.values(CommonwellOrganizationEnum).map(
                        (val: CommonwellOrganizationEnum) => val.toString()
                      )}
                      onChange={(_, data) => onChange(data?.toString())}
                      disablePortal
                      ListboxProps={{ style: { maxHeight: "12rem" } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...field}
                          required
                          inputRef={ref}
                          variant="outlined"
                          label="Organization type"
                        />
                      )}
                    />
                  )}
                />
                <ArrayFormInput
                  control={control}
                  name="locations"
                  nameToDisplay="Location"
                />
                <ArrayFormInput
                  control={control}
                  name="technicalContacts"
                  nameToDisplay="Contact"
                />
              </Stack>
            </Paper>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                mt: "1.5em",
              }}
            >
              <Button
                sx={{
                  alignSelf: "flex-end",
                  width: "20%",
                  fontSize: "1em",
                  ml: "1em",
                }}
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </Box>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
};
