import { SchemaConnector } from "./types";

export const csvConnectorSchema: SchemaConnector = {
  viewValue: "CSV",
  value: "CSVConnector",
  uiSchema: {
    "ui:submitButtonOptions": {
      submitText: "Create Connector",
      norender: false,
      props: {
        disabled: false,
        className: "btn btn-info",
      },
    },
    Name: { classNames: "connector-form-input" },
  },
  schema: {
    title: "CSV Connector",
    type: "object",
    required: ["Name"],
    properties: {
      Name: { type: "string", title: "Connector Name" },
    },
  },
};
