import { Box, CircularProgress, ListItemText } from "@mui/material";
import { startCase } from "lodash";

export interface SubmissionProgress {
  type: "create" | "update" | "delete";
  state: "error" | "idle" | "loading" | "success";
  entity: "Organization" | "Builder" | "Commonwell Organization" | "User";
  entityId?: string;
  errorMessage?: string;
}

const determineColor = (state: SubmissionProgress["state"]) => {
  switch (state) {
    case "success":
      return "#66bb6a";
    case "error":
      return "#f44336";
    case "loading":
    default:
      return "#949494";
  }
};

export const SubmissionProgressInfo = ({
  type,
  state,
  entity,
  entityId,
  errorMessage,
}: SubmissionProgress) => {
  const createMessage = () => {
    switch (state) {
      case "success":
        return `${startCase(entity)} ${type.concat("d")} successfully`;
      case "error":
        return type === "update"
          ? `${entity} update failed `
          : `${entity} ${type.slice(0, -1).concat("ion")} failed `;
      case "loading":
      default:
        return type === "update"
          ? `${entity} update in progress... `
          : `${entity} ${type.slice(0, -1).concat("ion")} in progress... `;
    }
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <ListItemText
          primary={createMessage()}
          secondary={state}
          sx={{ mr: 1.5, minWidth: "260px" }}
          secondaryTypographyProps={{
            sx: { color: determineColor(state) },
          }}
        />
        {state === "loading" && <CircularProgress size={50} sx={{ mr: 10 }} />}
      </Box>
      {state === "success" && (
        <ListItemText
          primary={`${startCase(type.concat("d"))} ${entity} id:`}
          secondary={entityId ?? "N/A"}
        />
      )}
      {state === "error" && (
        <ListItemText primary="Error message" secondary={errorMessage} />
      )}
    </>
  );
};
