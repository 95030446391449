import { createGlobalState } from "react-use";
import { defaultSnackbar } from "../../default-objects";
import { SnackbarProp } from "../../types";

export const useGlobalSnackbar =
  createGlobalState<SnackbarProp>(defaultSnackbar);

export const snackbarOpenPropBuilder = (passedProps: SnackbarProp) => {
  return {
    ...defaultSnackbar,
    ...passedProps,
    open: true,
  };
};
