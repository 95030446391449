import { useAuth0 } from "@auth0/auth0-react";
import {
  AcUnit,
  Build,
  Code,
  DirectionsRun,
  ElectricalServices,
  Schedule,
  Subscriptions,
  Work,
} from "@mui/icons-material";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { hasFullPermissions } from "../http/auth/builders";

interface MenuSectionProps {
  links: MenuLink[];
  title: string;
}

export interface MenuLink {
  view: string;
  route: string;
  id: number;
  icon: ReactElement;
}

const exportLinks: MenuLink[] = [
  { view: "Runs", route: "runs", id: 1, icon: <DirectionsRun /> },
  { view: "Jobs", route: "jobs", id: 2, icon: <Work /> },
  { view: "Schedule", route: "schedule", id: 3, icon: <Schedule /> },
  {
    view: "Connectors",
    route: "connectors",
    id: 4,
    icon: <ElectricalServices />,
  },
  { view: "Queries", route: "queries", id: 5, icon: <Code /> },
];

const subscriptionLinks: MenuLink[] = [
  {
    view: "Subscriptions",
    route: "subscriptions/list",
    id: 1,
    icon: <Subscriptions />,
  },
];

const builderProvisioningLinks: MenuLink[] = [
  {
    view: "Manage Builders",
    route: "builders",
    id: 1,
    icon: <Build />,
  },
];

const manageSnowflakeLinks: MenuLink[] = [
  {
    view: "Snowflake Accounts",
    route: "builder_admin/snowflake_accounts",
    id: 2,
    icon: <AcUnit />,
  },
];

const MenuSection = ({
  links: sectionConfig,
  title: sectionTitle,
}: MenuSectionProps) => {
  const navigate = useNavigate();

  const handleRouteChange = (config: MenuLink) => {
    if (config.route) {
      navigate(config.route);
    }
  };

  return (
    <List>
      <li>
        <Typography
          sx={{ mt: 0.5, ml: 1 }}
          color="text.secondary"
          display="block"
          variant="caption"
        >
          {sectionTitle}
        </Typography>
      </li>
      {sectionConfig.map((config, _index) => (
        <ListItem
          button
          key={config.id}
          onClick={() => handleRouteChange(config)}
        >
          <ListItemIcon>{config.icon}</ListItemIcon>
          <ListItemText primary={config.view} />
        </ListItem>
      ))}
    </List>
  );
};

export const AppDrawer = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [hasSnowflakePermission, setHasSnowflakePermission] =
    useState<boolean>(false);
  const drawerWidth = 220;

  useEffect(() => {
    const canManageSnowflake = async () => {
      const token = await getAccessTokenSilently();
      const hasPermission = await hasFullPermissions({
        service: "snowflake_admin",
        token,
      });
      setHasSnowflakePermission(hasPermission);
    };
    canManageSnowflake();
  }, [getAccessTokenSilently]);

  return (
    <Drawer
      sx={{
        top: 5,
        width: drawerWidth,
        flexShrink: 0,
        marginRight: 2,
        "& .MuiDrawer-paper": {
          position: "static",
          width: drawerWidth,
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <MenuSection links={exportLinks} title="Export" />
      <MenuSection links={subscriptionLinks} title="Subscriptions" />
      {hasSnowflakePermission && (
        <MenuSection links={manageSnowflakeLinks} title="Snowflake" />
      )}
      <MenuSection
        links={builderProvisioningLinks}
        title="Builder Provisioning"
      />
    </Drawer>
  );
};
