import { useAuth0 } from "@auth0/auth0-react";
import { Add } from "@mui/icons-material";
import {
  Alert,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fab,
  Snackbar,
} from "@mui/material";
import type {
  GridColDef,
  GridRowParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { format } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBuilders } from "../api";
import type { Builder, Data } from "../types";

const cardStyle = {
  minWidth: 275,
  marginTop: 2,
  marginBottom: 2,
  paddingLeft: 1,
  paddingRight: 1,
};

export interface BuilderData {
  name: string;
  id: string;
  updatedAt?: number;
  createdAt?: number;
}

export const BuilderList = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);

  const navigateCreateBuilder = () => navigate("/builders/create");
  const handleCloseSnackbar = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    setOpenSnackbar(false);
  };

  const {
    isLoading: isBuildersLoading,
    data: builders,
    error: buildersError,
  }: UseQueryResult<BuilderData[], Error> = useQuery<
    Data<Builder>[],
    Error,
    BuilderData[]
  >(
    ["builders"],
    async () => {
      const token = await getAccessTokenSilently();
      return getBuilders({ token, params: { "page[count]": 1000 } });
    },
    {
      select: (fetchedData) =>
        fetchedData.map((builder) => ({
          id: builder.id,
          name: builder.attributes.name,
          createdAt: builder.attributes.createdAt,
          updatedAt: builder.attributes.updatedAt,
        })),
      onError: () => setOpenSnackbar(true),
    }
  );

  const formatDate = ({ value }: GridValueFormatterParams<number>) =>
    format(new Date(value * 1000), "dd LLL yyyy, HH:mm");

  const builderCols: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 2 },
    { field: "id", headerName: "ID", flex: 2 },
    {
      field: "updatedAt",
      headerName: "Updated At",
      valueFormatter: formatDate,
      width: 150,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      valueFormatter: formatDate,
      width: 150,
    },
  ];

  return (
    <Box sx={{ minWidth: "940px" }}>
      <Card elevation={3} sx={cardStyle}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <CardHeader title="Builders list" />
          <CardActions sx={{ marginRight: "10px" }}>
            <Fab color="primary" onClick={navigateCreateBuilder}>
              <Add />
            </Fab>
          </CardActions>
        </Box>
        <CardContent sx={{ display: "flex", justifyContent: "center" }}>
          <DataGrid
            autoHeight
            columns={builderCols}
            rows={builders ?? []}
            getRowId={(row: BuilderData) => row.id}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 50]}
            hideFooterSelectedRowCount
            onPageSizeChange={setPageSize}
            loading={isBuildersLoading}
            localeText={{
              noRowsLabel: "No builders found",
            }}
            onRowClick={(params: GridRowParams<BuilderData>) =>
              navigate(`/builders/${params.id}`)
            }
          />
        </CardContent>
      </Card>
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {buildersError?.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
