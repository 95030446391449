import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, TextField } from "@mui/material";
import { camelCase } from "lodash";
import { highlight, languages } from "prismjs";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-sql";
import "prismjs/themes/prism-dark.css";
import { useEffect, useState } from "react";
import Editor from "react-simple-code-editor";
import { format } from "sql-formatter";
import { postApi } from "../api";
import { BuilderSelector } from "../components/BuilderSelector";
import { ResourceSelector } from "../components/ResourceSelector";
import { Builder, Data } from "../types";

export const QueryForm = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [query, setQuery] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [baseResource, setBaseResource] = useState<string>("");
  const [selectedBuilder, setSelectedBuilder] = useState<Data<Builder>>();
  const [saveForm, setSaveForm] = useState<boolean>(false);
  const [formatterError, setFormatterError] = useState<string>("");

  const handleSave = (e: React.FormEvent<HTMLFormElement> | undefined) => {
    if (e) {
      e.preventDefault();
      setSaveForm(true);
    }
  };

  const formatQuery = () => {
    try {
      setQuery(
        format(query, {
          language: "spark",
          tabWidth: 2,
          keywordCase: "upper",
          linesBetweenQueries: 2,
        })
      );
      setFormatterError("");
    } catch (error: any) {
      setFormatterError(error.message);
    }
  };

  /// SAVE FORM
  useEffect(() => {
    if (saveForm) {
      setSaveForm(false);
      getAccessTokenSilently().then((token) => {
        return postApi({
          data: {
            queryText: query,
            builderId: selectedBuilder?.id,
            queryKey: camelCase(name),
            queryBaseResource: baseResource,
          },
          url: "/query",
          token,
        });
      });
    }
  }, [saveForm]);

  // format after a pause in typing.
  useEffect(() => {
    const timer = setTimeout(() => {
      formatQuery();
    }, 1000);
    return () => clearTimeout(timer);
  }, [query]);

  const editorStyle: React.CSSProperties = {
    fontFamily: '"Fira code", "Fira Mono", monospace',
    fontSize: 12,
    marginBottom: 10,
    border: "solid",
    borderColor: "#D3D3D3",
    borderRadius: "5px",
    borderWidth: "1px",
    overflowY: "scroll",
  };

  return (
    <Box sx={{ backgroundColor: "white", padding: 2 }}>
      <form onSubmit={handleSave}>
        <Box sx={{ marginBottom: 1, display: "flex" }}>
          <BuilderSelector
            setBuilder={setSelectedBuilder}
            builder={selectedBuilder}
          />
          <Box sx={{ width: "10px" }} />
          <ResourceSelector setResource={setBaseResource} />
        </Box>

        <TextField
          id="query_name"
          label="Name"
          required
          sx={{ width: "100%", marginBottom: 1 }}
          onChange={(e) => setName(e.target.value)}
        />

        <Editor
          value={query}
          onValueChange={(value: string) => setQuery(value)}
          highlight={(code: string) => highlight(code, languages.sql, "sql")}
          padding={5}
          required
          placeholder="Enter query here..."
          style={{
            ...editorStyle,
            ...{ height: "400px" },
          }}
        />

        <Editor
          value={formatterError}
          onValueChange={(_value: string) => {}}
          readOnly
          highlight={(text: string) => text}
          padding={5}
          placeholder="Validation Output"
          style={{
            ...editorStyle,
            ...{ height: "150px" },
          }}
        />

        <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
          <Button variant="text" type="submit" disabled={formatterError !== ""}>
            Save Query
          </Button>
        </Box>
      </form>
    </Box>
  );
};
