import { Autocomplete, TextField } from "@mui/material";

interface ScheduleSelectorProps {
  scheduleId: string;
  setSchedule: (schedule: string) => void;
}

export const schedules = [
  { name: "On Read Store Rebuild", id: "onReadStoreRebuild" },
];

export const ScheduleSelector = ({
  scheduleId,
  setSchedule,
}: ScheduleSelectorProps) => {
  const handleScheduleOnChange = (
    _event: React.SyntheticEvent<Element, Event>,
    selection: { name: string; id: string } | null
  ) => {
    if (selection) setSchedule(selection.id);
  };

  const getScheduleFromId = (id: string) => schedules.find((s) => s.id === id);

  return (
    <div style={{ display: "flex" }}>
      <Autocomplete
        id="export-schedule"
        options={schedules}
        value={getScheduleFromId(scheduleId) || null}
        renderInput={(params) => (
          <TextField {...params} label="Export Schedule" variant="outlined" />
        )}
        getOptionLabel={(option) => option.name}
        onChange={(e, v) => handleScheduleOnChange(e, v)}
        style={{ width: 270, margin: "3px", marginBottom: 1 }}
      />
    </div>
  );
};
