import { useAuth0 } from "@auth0/auth0-react";
import { Box, Card, CardContent, CardHeader } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getApi } from "../api";

import { SnowflakeReaderAccount } from "../types/snowflake";

export const SnowflakeAccountManager = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [readerAccts, setReaderAccts] = useState<SnowflakeReaderAccount[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loadReaderAccts = (token: string) => {
    const url = "admin/snowflake-reader-acct";
    setIsLoading(true);

    getApi({ url, token }).then((acctResp: any) => {
      const acctList = acctResp?.data || [];
      setReaderAccts(acctList);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getAccessTokenSilently().then((token) => loadReaderAccts(token));
  }, []);

  const readerAccountCols: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Link to={params.row.locator}>{params.value}</Link>
      ),
    },
    {
      field: "locator",
      headerName: "Locator",
      width: 100,
    },
    {
      field: "url",
      headerName: "URL",
      width: 300,
      renderCell: (params: GridRenderCellParams<string>) => (
        <a href={params.value} target="_blank" rel="noreferrer">
          Snowflake site
        </a>
      ),
    },
  ];

  return (
    <Box sx={{ minWidth: "940px" }}>
      <Card
        elevation={3}
        sx={{
          marginY: 2,
          padding: 1,
        }}
      >
        <CardHeader title="Snowflake Reader Accounts" />
        <CardContent>
          <DataGrid
            autoHeight
            rows={readerAccts}
            columns={readerAccountCols}
            getRowId={(row) => row.locator}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 50]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            loading={isLoading}
          />
        </CardContent>
      </Card>
    </Box>
  );
};
