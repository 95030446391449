import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { JobsCreateForm } from "../../forms/JobCreateForm";
import { SnackbarProp } from "../../types";
import "./JobCreate.scss";

export const JobsCreatePage = () => {
  const navigate = useNavigate();

  const [snackbar, setSnackBar] = useState<SnackbarProp>();
  const [sbOpen, setSbOpen] = useState(false);

  const handleSbClose = () => {
    setSbOpen(false);
    setSnackBar(undefined);
    navigate("/jobs");
  };

  useEffect(() => {
    if (snackbar) {
      setSbOpen(true);
    }
  }, [snackbar]);

  return (
    <>
      <JobsCreateForm
        isEdit={false}
        setSnackBar={setSnackBar}
        onCancel={() => navigate("/jobs")}
      />
      {/* SNACKBAR */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={sbOpen}
        autoHideDuration={2000}
        onClose={handleSbClose}
      >
        <Alert
          variant="filled"
          onClose={handleSbClose}
          severity={snackbar?.severity}
          sx={{ width: "100%" }}
        >
          {snackbar?.message || "Missing Message"}
        </Alert>
      </Snackbar>
    </>
  );
};
