import { useAuth0 } from "@auth0/auth0-react";
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getApi } from "../api";
import { Builder, Data } from "../types/auth";
import { JobObject } from "../types/bdp";

interface JobSelectorProps {
  builder?: Data<Builder>;
  setSelectedJob: (job: JobObject) => void;
  selectedJob: JobObject;
}

export const JobSelector = ({ builder, setSelectedJob }: JobSelectorProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [jobs, setJobs] = useState<JobObject[]>([]);

  const jobLabel = (job: JobObject) => {
    return job?.jobName
      ? `${job?.meta?.builderName || job.builderId}: ${job?.jobName}`
      : "unknown job";
  };

  const handleJobOnChange = (_event: object, value: JobObject | null) => {
    if (value) setSelectedJob(value);
  };

  useEffect(() => {
    getAccessTokenSilently().then((token) =>
      getApi({
        url: builder?.id ? `/job/saved/${builder?.id}` : "/job/saved",
        token,
      }).then((response: any) => {
        const jobList: JobObject[] = response?.data || [];
        jobList.sort((a, b) => jobLabel(a).localeCompare(jobLabel(b)));

        setJobs(jobList);
      })
    );
  }, []);

  return (
    <Autocomplete
      options={jobs}
      getOptionLabel={jobLabel}
      id="job-selector"
      sx={{ width: 300 }}
      onChange={(e, v) => handleJobOnChange(e, v)}
      renderInput={(params) => (
        <TextField {...params} focused label="Select Saved Job" />
      )}
    />
  );
};
