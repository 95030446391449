import { SchemaConnector } from "./types";

export const postgresConnectorSchema: SchemaConnector = {
  viewValue: "Postgres",
  value: "PostgresConnector",
  uiSchema: {
    "ui:submitButtonOptions": {
      submitText: "Create Connector",
      norender: false,
      props: {
        disabled: false,
        className: "btn btn-info",
      },
    },
    Name: { classNames: "connector-form-input" },
    server: { classNames: "connector-form-input" },
    port: { classNames: "connector-form-input" },
    dbName: { classNames: "connector-form-input" },
    dbSchema: { classNames: "connector-form-input" },
    userName: { classNames: "connector-form-input" },
    password: { classNames: "connector-form-input" },
  },
  schema: {
    title: "Postgres Connector",
    type: "object",
    required: [
      "Name",
      "server",
      "port",
      "dbName",
      "userName",
      "password",
      "dbSchema",
    ],
    properties: {
      Name: { type: "string", title: "Connector Name" },
      server: { type: "string", title: "Postgres Server" },
      port: { type: "string", title: "Postgres Port" },
      dbName: { type: "string", title: "Postgres Database Name" },
      dbSchema: { type: "string", title: "Postgres Database Schema" },
      userName: { type: "string", title: "Postgres Username" },
      password: { type: "string", title: "Postgres Password" },
    },
  },
};
