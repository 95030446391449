import Client, { FhirResource } from "fhir-kit-client";
import type { OpPatch } from "fhir-kit-client/types/externals";
import type { OdsOrganizationProps } from "../../components/BuilderProvisioning/types";
import { fhirClient } from "../client";

async function getFhirClient(
  token: string,
  builderID?: string
): Promise<Client> {
  let customHeaders: HeadersInit = {};
  if (builderID) {
    customHeaders = {
      "Zus-Account": builderID,
    };
  }
  fhirClient.bearerToken = token;
  fhirClient.customHeaders = customHeaders;
  return fhirClient;
}

export const getOrganizations = async ({
  token,
  params,
}: {
  token: string;
  params?: object;
}): Promise<FhirResource | (FhirResource & { type: "searchset" })> => {
  const client = await getFhirClient(token);
  return client.resourceSearch({
    resourceType: "Organization",
    searchParams: {
      _total: "accurate",
      _count: 100,
      _offset: 0,
      ...params,
    },
  });
};

export const getOrganizationById = async ({
  token,
  organizationId,
}: {
  token: string;
  organizationId: string;
}): Promise<FhirResource> => {
  const client = await getFhirClient(token);
  return client.read({
    resourceType: "Organization",
    id: organizationId,
  });
};

export const createODSOrganization = async ({
  token,
  body,
  builderId,
}: {
  token: string;
  body: OdsOrganizationProps;
  builderId: string;
}): Promise<FhirResource> => {
  const client = await getFhirClient(token, builderId);
  const data = {
    resourceType: "Organization",
    identifier: [
      {
        use: "official",
        system: "https://zusapi.com/fhir",
        value: builderId,
      },
    ],
    ...body,
  };
  return client.create({
    resourceType: "Organization",
    body: data,
  });
};

export const patchOrganization = async ({
  token,
  body,
  resourceId,
  builderId,
}: {
  token: string;
  body: OpPatch[];
  resourceId: string;
  builderId: string;
}): Promise<FhirResource> => {
  const client = await getFhirClient(token, builderId);
  return client.patch({
    resourceType: "Organization",
    id: resourceId,
    JSONPatch: body,
  });
};
