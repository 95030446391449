import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { deleteUser } from "../../../api/auth/users";
import { queryClient } from "../../../api/client";
import type { BuilderUser, Data, User } from "../../../types";
import { SubmissionProgressInfo } from "../SubmissionProgressInfo";

interface DeleteUserDialogProps {
  builderId: string;
  user: BuilderUser;
  setUser: React.Dispatch<React.SetStateAction<BuilderUser | null>>;
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeleteUserDialog = ({
  builderId,
  user,
  openDialog,
  setUser,
  setOpenDialog,
}: DeleteUserDialogProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const { mutate, isIdle, status, error } = useMutation<
    Data<User>,
    Error,
    string
  >(
    async (userId: string) => {
      const token = await getAccessTokenSilently();
      return deleteUser({ token, userId });
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["builderUsers", builderId]),
    }
  );

  const onCloseDialog = () => {
    setUser(null);
    setOpenDialog(false);
  };

  return (
    <Dialog open={openDialog} onClose={onCloseDialog} fullWidth maxWidth="sm">
      <DialogTitle>Delete user</DialogTitle>
      <DialogContent>
        {isIdle ? (
          `Are you sure you want to delete user: ${user.name}?`
        ) : (
          <SubmissionProgressInfo
            type="delete"
            entity="User"
            state={status}
            entityId={user.id}
            errorMessage={error?.message}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog}>
          {["idle", "loading"].includes(status) ? "Cancel" : "Confirm"}
        </Button>
        {isIdle && <Button onClick={() => mutate(user.id)}>Delete</Button>}
      </DialogActions>
    </Dialog>
  );
};
