import { get, mapKeys } from "lodash";
import type {
  AuthArrayResponse,
  AuthSingleResponse,
  Data,
  User,
} from "../../types";
import { AUTH_URL } from "../constants";
import { deleteApi, getApi, patchApi, postApi } from "../methods";

interface UserBody {
  email: string;
  name: string;
  roleId: string;
  verificationEmail?: boolean;
  passwordResetEmail?: boolean;
  builderId?: string;
}

export const getUsers = async ({
  token,
  params,
}: {
  token: string;
  params?: object;
}): Promise<AuthArrayResponse<User>> => {
  const filters = get(params, "filter", {});
  const parameters = mapKeys(filters, (_, key) => `filter[${key}]`);
  const url = `${AUTH_URL}/users`;
  return getApi<AuthArrayResponse<User>>({ url, token, params: parameters });
};

export const createUser = async ({
  token,
  body,
}: {
  token: string;
  body: UserBody;
}): Promise<Data<User>> => {
  const url = `${AUTH_URL}/users`;
  const userData = {
    data: {
      type: "auth/users",
      attributes: {
        email: body.email,
        name: body.name,
        userType: "builder",
        // ENV RECOGNIITION!
        clientId: "PuzaR6b4U1l2wMC3qciU1qUJI2fOxJLw",
        sendPasswordResetEmail: body.passwordResetEmail,
        sendVerificationEmail: body.verificationEmail,
      },
      relationships: {
        "auth/builders": {
          data: {
            type: "auth/builders",
            id: body.builderId,
          },
        },
        "auth/roles": {
          data: {
            type: "auth/roles",
            id: body.roleId,
          },
        },
      },
    },
  };
  return postApi<AuthSingleResponse<User>>({
    url,
    data: userData,
    token,
  }).then((response) => response.data);
};

export const updateUser = async ({
  token,
  userId,
  body,
}: {
  token: string;
  userId: string;
  body: UserBody;
}): Promise<Data<User>> => {
  const url = `${AUTH_URL}/users/${userId}`;
  const userData = {
    data: {
      type: "auth/users",
      attributes: {
        email: body.email,
        name: body.name,
        userType: "builder",
      },
      relationships: {
        "auth/roles": {
          data: {
            type: "auth/roles",
            id: body.roleId,
          },
        },
      },
    },
  };
  return patchApi<AuthSingleResponse<User>>({
    url,
    data: userData,
    token,
  }).then((response) => response.data);
};

export const deleteUser = async ({
  token,
  userId,
}: {
  token: string;
  userId: string;
}): Promise<Data<User>> => {
  const url = `${AUTH_URL}/users/${userId}`;
  return deleteApi<AuthSingleResponse<User>>({ url, token }).then(
    (response) => response.data
  );
};
