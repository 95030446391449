import { bigQueryConnectorSchema } from "./schema_connector_big_query";
import { csvConnectorSchema } from "./schema_connector_csv";
import { postgresConnectorSchema } from "./schema_connector_postgres";
import { snowflakeConnectorSchema } from "./schema_connector_snowflake";
import { zusParquetConnectorSchema } from "./schema_connector_zus_parquet";
import { SchemaConnector } from "./types";

export const schemaConnectorList: SchemaConnector[] = [
  bigQueryConnectorSchema,
  snowflakeConnectorSchema,
  postgresConnectorSchema,
  zusParquetConnectorSchema,
  csvConnectorSchema,
];
