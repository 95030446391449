import { Builder, Data, JobObject, SnackbarProp } from "./types";

export const defaultBuilder: Data<Builder> = {
  type: "",
  id: "",
  attributes: { name: "" },
};

export const defaultJobObject: JobObject = {
  builderId: "",
  connectorTypes: [],
  jobName: "",
  endTime: 0,
  jobArgs: {},
  jobId: "",
  jobStatus: "",
  jobType: "",
  queries: [],
  stageStatuses: [],
  startTime: 0,
  meta: {},
};

export const defaultSelectedScheduleTime = "0";

export const defaultSelectedSchedule = "daily";

export const defaultSnackbar: SnackbarProp = {
  severity: "warning",
  message: "Missing Message!",
  open: false,
  anchorOrigin: { vertical: "bottom", horizontal: "center" },
  autoHideDuration: 6000,
  snackbarSx: {},
  alertSx: { width: "100%" },
  classes: {},
  alertVariant: "filled",
};
