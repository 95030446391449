import { get, mapKeys } from "lodash";
import type {
  AuthArrayResponse,
  AuthSingleResponse,
  Builder,
  Data,
} from "../../types";
import { AUTH_URL } from "../constants";
import { getApi, postApi } from "../methods";

interface BuilderOptions {
  token: string;
  params?: object;
  options?: object;
}

interface BuilderBody {
  name: string;
  namespace: string;
}

export const getBuilder = async ({
  token,
  builderId,
}: {
  token: string;
  builderId: string;
}): Promise<Data<Builder>> => {
  const url = `${AUTH_URL}/builders/${builderId}`;
  return getApi<AuthSingleResponse<Builder>>({ url, token }).then(
    (response) => response.data
  );
};

export const getBuilders = async ({
  token,
  params,
  options,
}: BuilderOptions): Promise<Data<Builder>[]> => {
  const url = `${AUTH_URL}/builders`;
  const filters = get(params, "filter", {});
  const parameters = mapKeys(filters, (_, key) => `filter[${key}]`);
  let res = await getApi<AuthArrayResponse<Builder>>({
    url,
    token,
    params: parameters,
    options,
  });
  let builders = [...res.data];
  while (res?.links?.next) {
    // eslint-disable-next-line no-await-in-loop
    res = await getApi<AuthArrayResponse<Builder>>({
      url: res.links.next,
      token,
    });
    builders = [...builders, ...res.data];
  }

  return builders;
};

export const createBuilder = async ({
  token,
  body,
}: {
  token: string;
  body: BuilderBody;
}): Promise<Data<Builder>> => {
  const url = `${AUTH_URL}/builders`;
  const builderData = {
    data: {
      type: "auth/builders",
      attributes: body,
    },
  };
  return postApi<AuthSingleResponse<Builder>>({
    url,
    data: builderData,
    token,
  }).then((response) => response.data);
};
