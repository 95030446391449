import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { resources as resourcesList } from "../resources";

interface ResourceSelectorProps {
  setResource: (resource: string) => void;
}

export const ResourceSelector = ({ setResource }: ResourceSelectorProps) => {
  const [resources, setResources] = useState<string[]>([]);
  const handleChange = (_event: object, value: string) => setResource(value);

  useEffect(() => {
    setResources(resourcesList);
  }, []);

  return (
    <Autocomplete
      sx={{ width: 300 }}
      onChange={() => handleChange}
      options={resources}
      getOptionLabel={(option: string) => option}
      renderInput={(params) => (
        <TextField {...params} focused label="Select Resource" />
      )}
    />
  );
};
