import { defaultJobComponent, defaultJobSchema } from "./schema_job_util";
import { SchemaJob } from "./types";

export const JobRunSchema: SchemaJob = {
  ...defaultJobSchema,
  jobStatusText: "Job has been started",
  value: "Run Saved Job",
  route: "run-job",
  buttonText: "Run Job",
  description: "",
  components: {
    ...defaultJobSchema.components,
    savedJobsList: {
      ...defaultJobComponent,
      show: true,
    },
  },
};
