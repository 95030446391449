import { useAuth0 } from "@auth0/auth0-react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { sortBy, startCase } from "lodash";
import { useEffect, useState } from "react";
import { deleteApi, getApi } from "../api";
import { getBuilders } from "../api/auth/builders";
import { useOpenAxiosErrorSnackBar, useOpenSnackBar } from "../hooks";
import { Builder, Data, Schedule } from "../types";
import { DeleteButtonWithConfirmation } from "./DeleteButtonWithConfirmation";

export const ScheduleViewer = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [builders, setBuilders] = useState<Data<Builder>[]>([]);
  const [setErrorSnackBar] = useOpenAxiosErrorSnackBar();
  const [setSnackBar] = useOpenSnackBar({ open: false });
  const [reload, setReload] = useState<number>(0);

  const getBuilderName = (builderId: string) =>
    builders?.find((item) => item.id === builderId)?.attributes.name;

  const handleDeleteSchedule = (deleteSchedule: Schedule) => {
    getAccessTokenSilently().then((token) =>
      deleteApi({
        url: `/job/scheduled/${deleteSchedule.builderId}/${deleteSchedule.scheduleKey}`,
        token,
      })
        .then(() => {
          setSnackBar({
            severity: "success",
            message: "Schedule Deleted!",
          });
          setReload(reload + 1);
        })
        .catch((err: AxiosError) => {
          setErrorSnackBar(err);
          setReload(reload + 1);
        })
    );
  };
  /// page load and page reload
  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      Promise.all([
        getBuilders({ token }),
        getApi({
          url: "/job/scheduled",
          token,
        }).then((result: any) => result?.data),
      ])
        .then(([bs, fetchedSchedules]) => {
          setBuilders(bs);

          const formattedSchedules = fetchedSchedules.map(
            (schedule: Schedule) => ({
              ...schedule,
              builderName: getBuilderName(schedule.builderId),
              scheduleFormatted: startCase(schedule.scheduleKey),
            })
          );
          setSchedules(
            sortBy(formattedSchedules, [
              (schedule: Schedule) => schedule?.builderName?.toLowerCase(),
              (schedule: Schedule) => schedule?.jobName?.toLowerCase(),
            ])
          );
        })
        .catch((err: AxiosError) => setErrorSnackBar(err));
    });
  }, [reload]);

  return (
    <div>
      <Typography variant="body2" sx={{ marginLeft: "15px" }}>
        <i>
          All Scheduled Jobs are run after the Read Store is rebuilt at the
          following times:&nbsp;
          {
            // Just need the time from this, to get a localize time
            [
              new Date("1/1/1970 4:00:00 AM UTC").toLocaleTimeString(),
              new Date("1/1/1970 10:00:00 AM UTC").toLocaleTimeString(),
              new Date("1/1/1970 4:00:00 PM UTC").toLocaleTimeString(),
              new Date("1/1/1970 10:00:00 PM UTC").toLocaleTimeString(),
            ].join(", ")
          }
        </i>
      </Typography>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Job Name</TableCell>
              <TableCell>Builder</TableCell>
              <TableCell>Schedule</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {schedules.map((schedule) => (
              <TableRow
                hover
                key={`${schedule.builderId}/${schedule.scheduleKey}`}
              >
                <TableCell>{schedule.jobName}</TableCell>
                <TableCell>{schedule.builderName}</TableCell>
                <TableCell>{schedule.scheduleFormatted}</TableCell>
                <TableCell width={15}>
                  <DeleteButtonWithConfirmation
                    handleDelete={() => handleDeleteSchedule(schedule)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
