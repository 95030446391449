import { useAuth0 } from "@auth0/auth0-react";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getApi } from "../api";
import { SnowflakeReaderAccount, SnowflakeUser } from "../types";

export const SnowflakeAccountDetail = () => {
  const { getAccessTokenSilently } = useAuth0();
  const params = useParams();

  const [readerAcct, setReaderAcct] = useState<SnowflakeReaderAccount>();
  const [users, setUsers] = useState<SnowflakeUser[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loadReaderAcct = (token: string) => {
    const url = `admin/snowflake-reader-acct/${params.account_identifier}`;

    getApi({ url, token }).then((acctResp: any) => {
      const acct = acctResp?.data || [];
      setReaderAcct(acct);
    });
  };

  const loadUsers = (token: string) => {
    const url = `admin/snowflake-reader-acct/${params.account_identifier}/user`;
    setIsLoading(true);

    getApi({ url, token }).then((resp: any) => {
      const userList = resp?.data || [];
      setUsers(userList);
      setIsLoading(false);
    });
  };

  // Load reader account details on page load.
  useEffect(() => {
    getAccessTokenSilently().then((token) => loadReaderAcct(token));
  }, []);

  // Load list of users in the reader account on page load
  useEffect(() => {
    getAccessTokenSilently().then((token) => loadUsers(token));
  }, []);

  const userCols: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
    },
    {
      field: "last_success_login",
      headerName: "Last Login",
      type: "dateTime",
      valueFormatter: (param: GridValueFormatterParams<Date>) =>
        param.value ? new Date(param.value).toLocaleString() : "",
      flex: 2,
    },
    {
      field: "disabled",
      headerName: "Enabled?",
      type: "boolean",
      valueGetter: (param: GridValueGetterParams<string>) =>
        param.value === "false",
      flex: 1,
    },
  ];

  return (
    <Box sx={{ minWidth: "940px" }}>
      <Card
        elevation={3}
        sx={{
          marginY: 2,
          padding: 1,
        }}
      >
        <CardHeader
          title={`Snowflake Reader Account for ${readerAcct?.name}`}
        />

        <CardContent>
          <Typography variant="subtitle1">Locator</Typography>
          <Typography variant="body2" color="text.secondary">
            {readerAcct?.locator}
          </Typography>

          <Typography variant="subtitle1" sx={{ marginTop: 3 }}>
            Snowflake Site
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {readerAcct?.url && (
              <a href={readerAcct?.url} target="_blank" rel="noreferrer">
                {readerAcct?.url}
              </a>
            )}
          </Typography>

          <Typography variant="subtitle1" sx={{ marginTop: 3 }}>
            Users
          </Typography>
          <DataGrid
            autoHeight
            rows={users}
            columns={userCols}
            getRowId={(row: SnowflakeUser) => row.name}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 50]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            loading={isLoading}
            disableSelectionOnClick
          />
        </CardContent>
      </Card>
    </Box>
  );
};
