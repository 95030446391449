import { useAuth0 } from "@auth0/auth0-react";
import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postApi } from "../api";
import { JobSelector } from "../components/SavedJobSelector";
import { defaultBuilder, defaultJobObject } from "../default-objects";
import "../pages/JobCreate/JobCreate.scss";
import { JobObject, SnackbarProp } from "../types";

interface JobRunFormProps {
  onCancel?: () => void;
  setSnackBar?: (sb: SnackbarProp) => void;
}

export const JobRunForm = ({
  onCancel = () => {},
  setSnackBar = (_sb) => {},
}: JobRunFormProps) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  // create job vars
  const [selectedJob, setSelectedJob] = useState<JobObject>(defaultJobObject);
  const [formIsValid, setFormIsValid] = useState(false);
  const [runJob, setRunJob] = useState(0);
  const [loading, setLoading] = useState(false);

  // RUN JOB
  useEffect(() => {
    if (runJob) {
      getAccessTokenSilently()
        .then((token) => {
          setLoading(true);
          return postApi<{
            jobId: string;
            jobConfig: any;
            executionArn: string | undefined;
            startDate: Date | undefined;
          }>({
            url: "/job/saved/run",
            data: {
              builderId: selectedJob?.builderId,
              jobName: selectedJob?.jobName,
            },
            token,
          });
        })
        .then((jobResponse) => {
          setSnackBar({ message: "good" });
          const { jobId, builderId } = jobResponse?.jobConfig || {};
          const navUrl =
            jobId && builderId ? `/jobs/${builderId}/${jobId}` : "/jobs";

          setTimeout(() => navigate(navUrl), 1500);
        })
        .catch((_err) => {
          setSnackBar({ message: "bad" });
          onCancel();
        });
    }
  }, [runJob]);

  // Check if form valid Job
  useEffect(() => {
    const newFormIsValid = selectedJob?.builderId !== "";

    if (newFormIsValid !== undefined && newFormIsValid !== formIsValid) {
      setFormIsValid(newFormIsValid);
    }
  }, [selectedJob]);

  const startJob = () => {
    setRunJob(runJob + 1);
  };
  return (
    <Box sx={{ width: 300, height: 200 }}>
      {/* SPINNER */}
      <Backdrop open={loading} onClick={() => {}}>
        <CircularProgress />
      </Backdrop>

      {/* SAVED JOBS LIST SELECTOR */}
      {!loading && (
        <>
          <Box className="page-component" sx={{ paddingTop: 1 }}>
            <JobSelector
              builder={defaultBuilder}
              selectedJob={selectedJob}
              setSelectedJob={setSelectedJob}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              disabled={!formIsValid}
              onClick={startJob}
            >
              Start Job
            </Button>
            <Button
              sx={{ marginLeft: 2 }}
              variant="contained"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};
