// Auth0Config returns the config that is appropriate for the environment

// being used (prod, staging, or local dev).
export function Auth0Config() {
  return {
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    redirectUri: import.meta.env.VITE_REDIRECT_URI || window.location.origin,
    audience: import.meta.env.VITE_AUTH0_CLIENT_AUDIENCE,
  };
}
