import { useAuth0 } from "@auth0/auth0-react";
import AddIcon from "@mui/icons-material/Add";
import ErrorIcon from "@mui/icons-material/Error";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getApi } from "../../api";
import { PageHeader } from "../../components/PageHeader";
import { getBuilders } from "../../http/auth/builders";
import { Builder, Connector, Data } from "../../types";
import "./Connectors.scss";

export const ConnectorsPage = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const [connectors, setConnectors] = useState<Connector[]>([]);
  const [builders, setBuilders] = useState<Data<Builder>[]>([]);
  const [selectedConnector, setSelectedConnector] = useState<Connector>();
  const [selectedBuilder, setSelectedBuilder] = useState<Data<Builder>>();

  const getConnectors = (token: string) => {
    const url = selectedBuilder?.id
      ? `/connector/${selectedBuilder?.id}`
      : "/connector";

    getApi({
      url,
      token,
    })
      .then((connectorsRes: any) => connectorsRes?.data || [])
      .then((cs: Connector[]) => {
        const newConnectors = cs.map((c) => {
          const builderName =
            builders?.find((b) => b.id === c.builderId)?.attributes?.name ?? "";
          return { ...c, builderName };
        });
        setConnectors(newConnectors);
        setSelectedConnector(newConnectors?.[0]);
      });
  };

  /// PAGE LOAD
  useEffect(() => {
    getAccessTokenSilently().then((token) =>
      getBuilders({ token }).then((newBuilders: Data<Builder>[]) => {
        setBuilders(newBuilders);
        getConnectors(token);
      })
    );
  }, []);

  // had to break out the get connectors due to race condition of setting builders and getting connector's builders name
  useEffect(() => {
    getAccessTokenSilently().then((token) => getConnectors(token));
  }, [builders, selectedBuilder]);

  const handleBuilderOnChange = (_event: object, value: Data<Builder>) => {
    setSelectedConnector(undefined);
    setConnectors([]);
    setSelectedBuilder(value);
  };

  return (
    <Box sx={{ backgroundColor: "white", width: "90%" }}>
      <PageHeader
        title="Connectors"
        createAction={() => {
          navigate("/connectors/create");
        }}
      />
      <Box sx={{ display: "flex", ml: "5em" }}>
        <Box className="connector-list">
          {/* BUILDER SELECTOR */}
          <Autocomplete
            options={builders}
            getOptionLabel={(option: Data<Builder>) => option.attributes.name}
            sx={{ width: 300, marginBottom: 3 }}
            onChange={() => handleBuilderOnChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                color="secondary"
                focused
                label="Builder"
              />
            )}
          />
          {/* BUILDER NOT SELECTED EMPTY STATE */}

          {/* NO CONNECTORS EMPTY STATE */}
          {connectors?.length === 0 && (
            <Typography variant="body2">
              <i>Please Add a Connector</i>
            </Typography>
          )}
          <List sx={{ bgcolor: "background.paper" }}>
            {/* CONNECTOR LIST */}
            {connectors?.map((connector: Connector) => {
              return (
                <ListItem
                  onClick={() => setSelectedConnector(connector)}
                  key={`${connector.connectorName}-${connector.builderId}`}
                  selected={
                    `${connector.connectorName}-${connector.builderId}` ===
                    `${selectedConnector?.connectorName}-${selectedConnector?.builderId}`
                  }
                >
                  <ListItemButton color="success">
                    <ListItemText
                      primary={
                        connector.connectorName
                          ? connector.connectorName
                          : connector.connectorType
                      }
                      secondary={connector.builderName || connector.builderId}
                    />
                    {connector.error && (
                      <ListItemIcon sx={{ size: "100%" }}>
                        <ErrorIcon sx={{ marginLeft: "auto" }} color="error" />
                      </ListItemIcon>
                    )}
                  </ListItemButton>
                </ListItem>
              );
            })}

            {selectedBuilder && (
              <>
                {(connectors?.length ?? -1) > 0 && (
                  <Divider sx={{ marginBottom: 3 }} />
                )}{" "}
                <ListItem
                  button
                  key="add-connector-button"
                  onClick={() => navigate("/connectors/create")}
                >
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary="Create Connector" />
                </ListItem>
              </>
            )}
          </List>
        </Box>
        {/* CONNECTOR DETAIL */}
        {selectedConnector && (
          <Box className="connector-detail">
            <Card>
              <CardHeader
                title={
                  selectedConnector?.connectorName
                    ? selectedConnector?.connectorName
                    : selectedConnector?.connectorType
                }
              />
              <CardContent>
                {selectedConnector.error && (
                  <span>Error on looking up arguments for this connector</span>
                )}
                {Object.entries(selectedConnector?.args || []).map(
                  ([key, val]) => {
                    return (
                      <Box key={key} sx={{ marginBottom: 1 }}>
                        <Typography variant="caption">{key}</Typography>
                        <Typography variant="body2"> {val}</Typography>
                      </Box>
                    );
                  }
                )}
              </CardContent>
            </Card>
          </Box>
        )}
      </Box>
    </Box>
  );
};
