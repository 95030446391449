import { defaultJobComponent, defaultJobSchema } from "./schema_job_util";
import { SchemaJob } from "./types";

export const jobCreateExportAllSchema: SchemaJob = {
  ...defaultJobSchema,
  value: "Export All Builder Data",
  jobStatusText: "Job has been saved",
  route: "export-all",
  buttonText: "Save Job",
  description: "",
  components: {
    ...defaultJobSchema.components,
    builderSelector: { ...defaultJobComponent, show: true },
    jobNameInput: { ...defaultJobComponent, show: true },
    connectorSelector: { ...defaultJobComponent, show: true },
  },
};
