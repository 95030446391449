import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { isVpnError } from "../../api/client";
import { defaultSnackbar } from "../../default-objects";
import { SnackbarProp } from "../../types";
import { useNetworkErrorOpen } from "../useNetworkErrorOpen";
import { snackbarOpenPropBuilder } from "./useGlobalSnackbar";
import { useOpenSnackBar } from "./useOpenSnackBar";

export const useOpenAxiosErrorSnackBar = (initSb?: SnackbarProp) => {
  const [setOpenSnackBar] = useOpenSnackBar(undefined);
  const [snackbar, setSnackBar] = useState<SnackbarProp>(() => {
    return !initSb ? { open: false } : initSb;
  });
  const [, setOpenNetworkError] = useNetworkErrorOpen();

  const setter = (err: AxiosError) => {
    if (isVpnError(err)) {
      setOpenNetworkError(true);
    } else {
      const body: any = err?.response?.data || {};
      setSnackBar(
        snackbarOpenPropBuilder({
          ...snackbar,
          severity: "error",
          message: body?.message
            ? JSON.stringify(body?.message)
            : err?.message || "Unknown Message",
          open: true,
        })
      );
    }
  };

  useEffect(() => {
    setOpenSnackBar({ ...defaultSnackbar, ...snackbar, open: true });
  }, [snackbar]);

  return [setter] as const;
};
