import { Box } from "@mui/material";
import { JobRunForm } from "../../forms/JobRunForm";
import "../JobCreate/JobCreate.scss";

export const JobRunCreatePage = () => {
  return (
    <Box sx={{ marginBottom: 10, padding: 2, backgroundColor: "white" }}>
      <JobRunForm />
    </Box>
  );
};
