import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import type { UserRole } from "../../../types";
import { UserForm } from "./UserForm";

interface CreateUserDialogProps {
  builderId: string;
  roles: UserRole[];
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateUserDialog = ({
  builderId,
  roles,
  openDialog,
  setOpenDialog,
}: CreateUserDialogProps) => {
  const onCloseDialog = () => setOpenDialog(false);
  return (
    <Dialog open={openDialog} onClose={onCloseDialog} fullWidth maxWidth="sm">
      <DialogTitle>Create user</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <UserForm
          type="create"
          builderId={builderId}
          roles={roles}
          setOpenDialog={setOpenDialog}
        />
      </DialogContent>
    </Dialog>
  );
};
