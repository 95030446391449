import { SchemaConnector } from "./types";

export const snowflakeConnectorSchema: SchemaConnector = {
  viewValue: "Snowflake",
  value: "SnowflakeConnector",
  uiSchema: {
    "ui:submitButtonOptions": {
      submitText: "Create Connector",
      norender: false,
      props: {
        disabled: false,
        className: "btn btn-info",
      },
    },
    Name: { classNames: "connector-form-input" },
    sfURL: { classNames: "connector-form-input", "ui:widget": "uri" },
    sfPassword: { classNames: "connector-form-input" },
    sfRole: { classNames: "connector-form-input" },
    sfAccount: { classNames: "connector-form-input" },
    sfDatabase: { classNames: "connector-form-input" },
    sfSchema: { classNames: "connector-form-input" },
    warehouse: { classNames: "connector-form-input" },
    sfUser: { classNames: "connector-form-input" },
    sfShare: { classNames: "connector-form-input" },
  },
  schema: {
    title: "Snowflake Connector",
    type: "object",
    required: [
      "Name",
      "sfURL",
      "sfPassword",
      "sfRole",
      "sfAccount",
      "sfDatabase",
      "sfSchema",
      "warehouse",
      "sfUser",
    ],
    properties: {
      Name: { type: "string", title: "Connector Name" },
      sfURL: {
        type: "string",
        title: "Snowflake URL",
      },
      sfPassword: { type: "string", title: "Snowflake Password" },
      sfRole: { type: "string", title: "Snowflake Role" },
      sfAccount: { type: "string", title: "Snowflake Account" },
      sfDatabase: { type: "string", title: "Snowflake Database" },
      sfSchema: { type: "string", title: "Snowflake Schema" },
      warehouse: { type: "string", title: "Snowflake Warehouse" },
      sfUser: { type: "string", title: "Snowflake User" },
      sfShare: { type: "string", title: "Snowflake Share" },
    },
  },
};
