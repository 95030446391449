import { HealthAndSafety } from "@mui/icons-material";
import { Chip } from "@mui/material";

interface CommonwellChipProps {
  color:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
  label: string;
}

export const CommonwellChip = ({ color, label }: CommonwellChipProps) => {
  return (
    <Chip
      sx={{ fontSize: "1em", fontWeight: "bold" }}
      icon={<HealthAndSafety />}
      label={label}
      color={color}
      size="medium"
      variant="outlined"
    />
  );
};
