import { Box, Modal, Typography } from "@mui/material";

interface ModalSimpleProps {
  open: boolean;
  setOpen: (_open: boolean) => void;
  text: string;
}

export const ModalSimple = ({ open, setOpen, text }: ModalSimpleProps) => {
  const handleModalClose = () => {
    setOpen(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {text}
        </Typography>
      </Box>
    </Modal>
  );
};
