import { useAuth0 } from "@auth0/auth0-react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { postApi } from "../api";
import { BuilderSelector } from "../components/BuilderSelector";
import { ConnectorSelector } from "../components/ConnectorSelector";
import { QuerySelector } from "../components/QuerySelector";
import { defaultBuilder } from "../default-objects";
import "../pages/JobCreate/JobCreate.scss";
import type {
  Builder,
  Connector,
  Data,
  JobObject,
  Query,
  SavedJob,
  SnackbarProp,
} from "../types";

interface JobFormProps {
  isEdit?: boolean;
  savedJob?: SavedJob;
  onSaveComplete?: () => void;
  setSnackBar: (_sb: SnackbarProp) => void;
  onCancel: () => void;
}

export const JobsCreateForm = ({
  isEdit = false,
  savedJob,
  onCancel = () => {},
  onSaveComplete = () => {},
  setSnackBar = (_sb) => {},
}: JobFormProps) => {
  const { getAccessTokenSilently } = useAuth0();

  // create job vars
  const [ignoreErrors, setIgnoreErrors] = useState<boolean>(true);
  const [exportAll, setExportAll] = useState<boolean>(true);
  const [jobName, setJobName] = useState<string>("");
  const [jobNameIsValid, setJobNameIsValid] = useState<boolean>(false);
  const [selectedConnectors, setSelectedConnectors] = useState<Connector[]>([]);
  const [selectedBuilder, setSelectedBuilder] =
    useState<Data<Builder>>(defaultBuilder);
  const [selectedQueries, setSelectedQueries] = useState<Query[]>([]);

  // helper vars
  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [saveJob, setSaveJob] = useState<number>(0);

  const handleJobNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (jobNameIsValid !== event?.target?.validity?.valid) {
      setJobNameIsValid(event?.target?.validity?.valid);
    }
    setJobName(event.target.value);
  };

  // SAVE JOB
  useEffect(() => {
    if (!saveJob) return;

    const defaultBody: JobObject = {
      builderId: selectedBuilder?.id || "",
      queries: exportAll === true ? [] : selectedQueries,
      jobName,
      connectorTypes: selectedConnectors.map((c) => c.connectorName),
      meta: {
        builderName: selectedBuilder?.attributes?.name,
        ignoreErrors: ignoreErrors === true ? "true" : "false",
        jobName,
        exportAll: exportAll === true ? "true" : "false",
      },
    };
    setLoading(true);

    getAccessTokenSilently()
      .then((token) => {
        return postApi({
          url: "/job/saved",
          data: { ...defaultBody },
          token,
        });
      })
      .then(() => {
        setSnackBar({
          severity: "success",
          message: "Job Created!",
        });
        setLoading(false);
        onSaveComplete();
      })
      .catch((err) => {
        setLoading(false);
        setSnackBar({
          severity: "error",
          message: `Error Saving Job ${JSON.stringify(err)}`,
        });
        onSaveComplete();
      });
  }, [saveJob]);

  // CREATE JOB
  useEffect(() => {
    if (!isEdit) return;

    const builderId = savedJob?.builderId || "";
    const builderName = savedJob?.meta?.builderName || "";
    const newConnectors = (savedJob?.connectorTypes || []).map(
      (connectorName: string) => ({
        connectorName,
        error: false,
        builderId,
        connectorType: "",
        connectorArgs: {},
        builderName,
        args: {},
      })
    );
    setIgnoreErrors(!!savedJob?.meta?.ignoreErrors || true);
    setExportAll(savedJob?.queries?.length === 0);
    setJobName(savedJob?.jobName || "");
    setSelectedConnectors(newConnectors);
    setSelectedQueries(savedJob?.queries || []);
    setJobNameIsValid(true);
    setSelectedBuilder({
      type: "",
      id: builderId,
      attributes: {
        name: builderName,
      },
    });
  }, []);

  // Check if form valid Job
  useEffect(() => {
    const newFormIsValid =
      selectedBuilder !== undefined &&
      (exportAll || selectedQueries?.length > 0) &&
      selectedConnectors?.length > 0 &&
      jobNameIsValid === true &&
      jobName !== undefined;

    if (newFormIsValid !== undefined && newFormIsValid !== formIsValid) {
      setFormIsValid(newFormIsValid);
    }
  }, [
    selectedBuilder,
    selectedQueries,
    selectedConnectors,
    jobNameIsValid,
    jobName,
  ]);

  return (
    <Box
      sx={{
        marginBottom: 1,
        padding: 2,
        backgroundColor: "white",
        minWidth: "940px",
      }}
    >
      {/* SPINNER */}
      <Backdrop open={loading}>
        <CircularProgress />
      </Backdrop>
      {/* BUILDER SELECTOR */}
      {!isEdit && (
        <Box className="page-component">
          <BuilderSelector
            builder={selectedBuilder}
            setBuilder={setSelectedBuilder}
          />
        </Box>
      )}
      {/* JOB NAME INPUT */}
      {!isEdit && (
        <Box className="page-component">
          <TextField
            id="jobName"
            label="Job Name"
            value={jobName}
            onChange={handleJobNameChange}
            required
            variant="standard"
            sx={{ width: "50%", marginBottom: 1 }}
          />
        </Box>
      )}
      {/* EXPORT ALL  */}
      <Box className="page-component">
        <FormGroup sx={{ display: "flex" }}>
          <FormControlLabel
            control={
              <Switch
                checked={exportAll}
                onChange={() => setExportAll(!exportAll)}
                name="ExportAllSwitch"
              />
            }
            label="Export All"
          />
        </FormGroup>
      </Box>
      {/* IGNORE ERRORS */}
      <Box className="page-component">
        <FormGroup sx={{ display: "flex" }}>
          <FormControlLabel
            control={
              <Switch
                checked={ignoreErrors}
                onChange={() => setIgnoreErrors(!ignoreErrors)}
                name="IgnoreAllErrorsSwitch"
              />
            }
            label="Ignore All Errors"
          />
        </FormGroup>
      </Box>
      {/* QUERIES  */}
      {!exportAll && (
        <Box className="page-component">
          <Typography className="page-text" variant="h1">
            Select Queries
          </Typography>
          <QuerySelector
            setSelectedQueries={setSelectedQueries}
            selectedQueries={selectedQueries}
            builder={selectedBuilder}
            editingJob
          />
        </Box>
      )}
      {/* CONNECTORS */}
      <Box className="page-component">
        <Typography className="page-text" variant="h1">
          Select Connectors
        </Typography>
        <ConnectorSelector
          builder={selectedBuilder}
          setSelectedConnectors={setSelectedConnectors}
          selectedConnectors={selectedConnectors}
        />
      </Box>
      {/* SAVE JOB BUTTON */}
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          variant="contained"
          disabled={!formIsValid}
          onClick={() => setSaveJob(saveJob + 1)}
        >
          Save Job
        </Button>

        {/* CANCEL BUTTON */}
        <Button
          variant="contained"
          onClick={() => onCancel()}
          sx={{ marginLeft: 2 }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
