import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../components/PageHeader";
import { SavedJobViewer } from "../../components/SavedJobViewer";

export const JobsListPage = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ backgroundColor: "white", width: "90%" }}>
      <PageHeader
        title="Jobs"
        createAction={() => {
          navigate("/jobs/create");
        }}
      />
      <SavedJobViewer />
    </Box>
  );
};
