import { Box, Card, CardContent, CardHeader } from "@mui/material";
import { BuilderCreationForm } from "../components/BuilderProvisioning/BuilderCreationForm";

const cardStyle = {
  minWidth: 275,
  marginTop: 2,
  marginBottom: 2,
  paddingLeft: 1,
  paddingRight: 1,
};

export const BuilderCreate = () => {
  return (
    <Box sx={{ width: "680px", minWidth: "280px" }}>
      <Card elevation={3} sx={cardStyle}>
        <CardContent sx={{ maxWidth: "95%", margin: "auto" }}>
          <CardHeader title="Builder Creation Form" />
          <BuilderCreationForm />
        </CardContent>
      </Card>
    </Box>
  );
};
