import { useAuth0 } from "@auth0/auth0-react";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getApi } from "../api";
import { getBuilders } from "../api/auth/builders";
import { PageHeader } from "../components/PageHeader";

import { Builder, Data } from "../types/auth";
import { Query } from "../types/bdp";

interface PageData {
  loading: boolean;
  queries: Query[];
  builders: Data<Builder>[];
  selectedBuilder?: Data<Builder>;
}

export const QueriesPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [pageData, setPageData] = useState<PageData>({
    loading: true,
    builders: [],
    queries: [],
  });

  /// PAGE LOAD
  useEffect(() => {
    getAccessTokenSilently().then((token) =>
      getBuilders({ token }).then((builders: Data<Builder>[]) => {
        setPageData({
          ...pageData,
          loading: false,
          builders,
        });
      })
    );
  }, []);

  useEffect(() => {
    if (pageData.selectedBuilder) {
      getAccessTokenSilently().then((token) =>
        getApi({
          token,
          url: `/query/${pageData.selectedBuilder?.id}`,
        })
          .then((response: any) => response?.data)
          .then((queries: Query[]) => {
            setPageData({
              ...pageData,
              queries,
            });
          })
      );
    }
  }, [pageData.selectedBuilder]);

  const handleBuilderOnChange = (
    _event: React.SyntheticEvent<Element, Event>,
    builder: Data<Builder> | null
  ) => {
    if (builder)
      setPageData({
        ...pageData,
        selectedBuilder: builder,
      });
  };

  return (
    <Box sx={{ backgroundColor: "white", width: "90%" }}>
      <PageHeader
        title="Queries"
        createAction={() => {
          navigate("/queries/create");
        }}
      />
      <Box sx={{ marginBottom: 10 }}>
        <Card
          elevation={3}
          sx={{
            minWidth: 275,
            padding: 2,
            m: 3,
          }}
        >
          <CardHeader title="Select a builder" />
          <Autocomplete
            options={pageData.builders}
            sx={{ width: 300 }}
            getOptionLabel={(option) => option.attributes.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, v) => handleBuilderOnChange(e, v)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                color="secondary"
                focused
                label="Builder"
              />
            )}
          />
        </Card>
        {[...pageData.queries].sort((a, b) =>
          a.queryKey.localeCompare(b.queryKey)
        ) &&
          pageData.queries.map((query: Query) => {
            return (
              <Card key={query.queryKey} sx={{ marginBottom: 2 }}>
                <CardHeader title={query.name} />
                <CardContent>
                  {query.builderId === "zus-base" && (
                    <Typography sx={{ mb: 2 }} color="text.secondary">
                      Zus Base Query
                    </Typography>
                  )}
                  <code>{query.queryText}</code>
                </CardContent>
              </Card>
            );
          })}
      </Box>
    </Box>
  );
};
