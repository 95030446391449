import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useState } from "react";

export interface DeleteButtonWithConfirmationProps {
  handleDelete: () => void;
  titleText?: string;
}

export const DeleteButtonWithConfirmation = ({
  handleDelete,
  titleText = "Are you sure you want to delete?",
}: DeleteButtonWithConfirmationProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleDeleteConfirmed = () => {
    handleDelete();
    setModalOpen(false);
  };

  return (
    <>
      {/* BUTTON */}
      <IconButton aria-label="delete" onClick={() => setModalOpen(true)}>
        <DeleteIcon />
      </IconButton>

      {/* MODAL */}
      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        aria-describedby="delete confirmation"
      >
        <DialogTitle id="confirm-dialog-title">{titleText}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleted resources cannot be recovered
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDeleteConfirmed()}>Yes</Button>
          <Button onClick={() => handleModalClose()} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
