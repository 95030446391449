import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ScheduleForm } from "../forms/ScheduleForm";
import "./JobCreate/JobCreate.scss";

export const SchedulePage = () => {
  const navigate = useNavigate();

  const handleSave = () => {
    navigate("/schedule");
  };

  return (
    <Box sx={{ backgroundColor: "white", padding: 3, maxWidth: "550px" }}>
      <ScheduleForm isEdit={false} onSaveComplete={() => handleSave()} />
    </Box>
  );
};
