import { useAuth0 } from "@auth0/auth0-react";
import { Add, Delete, Edit } from "@mui/icons-material";
import { Alert, Box, Fab, Paper, Snackbar, Typography } from "@mui/material";
import type {
  GridActionsColDef,
  GridColDef,
  GridRowParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useState } from "react";
import { getRoles, getUsers } from "../../../api";
import type {
  BuilderUser,
  Data,
  Role,
  RoleName,
  User,
  UserRole,
} from "../../../types";
import { CreateUserDialog } from "./CreateUserDialog";
import { DeleteUserDialog } from "./DeleteUserDialog";
import { UpdateUserDialog } from "./UpdateUserDialog";

export const UserList = ({ builderId }: { builderId: string }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [selectedUser, setSelectedUser] = useState<BuilderUser | null>(null);
  const [openEditUserDialog, setOpenEditUserDialog] = useState<boolean>(false);
  const [openCreateUserDialog, setOpenCreateUserDialog] =
    useState<boolean>(false);
  const [openDeleteUserDialog, setOpenDeleteUserDialog] =
    useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);

  const handleCloseSnackbar = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") setOpenSnackbar(false);
  };

  const availableRoles: RoleName[] = [
    "Builder Admin",
    "Read Builders",
    "Care Team User",
  ];
  const {
    isLoading: isLoadingRoles,
    data: roles,
    error: rolesError,
  }: UseQueryResult<UserRole[], Error> = useQuery<
    Data<Role>[],
    Error,
    UserRole[]
  >(
    ["roles"],
    async () => {
      const token = await getAccessTokenSilently();
      const response = await getRoles({
        token,
        params: {
          filter: {
            name: availableRoles,
          },
        },
      });
      return response.data;
    },
    {
      select: (fetchedData) =>
        fetchedData
          .filter((role) => role.attributes.description !== "PRM Builder Admin")
          .map((role) => {
            return {
              id: role.id,
              name: role.attributes.name,
            };
          }),
      onError: () => setOpenSnackbar(true),
    }
  );

  const {
    isLoading: isBuilderUsersLoading,
    data: builderUsers,
    error: builderUsersError,
  }: UseQueryResult<BuilderUser[], Error> = useQuery<
    Data<User>[],
    Error,
    BuilderUser[]
  >(
    ["builderUsers", builderId],
    async () => {
      const token = await getAccessTokenSilently();
      const response = await getUsers({
        token,
        params: {
          filter: {
            userType: "builder",
            builderId,
          },
        },
      });
      return response.data;
    },
    {
      select: (fetchedData) =>
        fetchedData.map((user) => {
          return {
            id: user.id,
            name: user.attributes.name,
            email: user.attributes.email,
            role: user.relationships?.["auth/roles"]?.data.id,
          };
        }),
      onError: () => setOpenSnackbar(true),
    }
  );

  const mapUserRoleIdToName = (
    params: GridValueGetterParams<string, BuilderUser>
  ) =>
    roles?.find((role) => role.id === params.row.role)?.name ?? params.row.role;

  const userCols: (GridColDef | GridActionsColDef)[] = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "role",
      headerName: "Role",
      valueGetter: mapUserRoleIdToName,
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<Edit fontSize="large" />}
          onClick={() => {
            setSelectedUser(params.row as BuilderUser);
            setOpenEditUserDialog(true);
          }}
          label="edit"
        />,
        <GridActionsCellItem
          icon={<Delete fontSize="large" />}
          onClick={() => {
            setSelectedUser(params.row as BuilderUser);
            setOpenDeleteUserDialog(true);
          }}
          label="delete"
        />,
      ],
    },
  ];

  return (
    <Paper
      elevation={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        p: "1em",
        mt: 3,
        mb: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "1em",
        }}
      >
        <Typography variant="h5">Builder users</Typography>
        <Fab color="primary" onClick={() => setOpenCreateUserDialog(true)}>
          <Add />
        </Fab>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <DataGrid
          autoPageSize
          autoHeight
          columns={userCols}
          rows={builderUsers ?? []}
          getRowId={(row: BuilderUser) => row.name}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          hideFooterSelectedRowCount
          onPageSizeChange={setPageSize}
          loading={isBuilderUsersLoading && isLoadingRoles}
          localeText={{
            noRowsLabel: "No users found",
          }}
        />
      </Box>
      <CreateUserDialog
        builderId={builderId}
        roles={roles ?? []}
        openDialog={openCreateUserDialog}
        setOpenDialog={setOpenCreateUserDialog}
      />
      {selectedUser && (
        <>
          <DeleteUserDialog
            builderId={builderId}
            user={selectedUser}
            setUser={setSelectedUser}
            openDialog={openDeleteUserDialog}
            setOpenDialog={setOpenDeleteUserDialog}
          />
          <UpdateUserDialog
            builderId={builderId}
            roles={roles ?? []}
            exisitingUser={selectedUser}
            setUser={setSelectedUser}
            openDialog={openEditUserDialog}
            setOpenDialog={setOpenEditUserDialog}
          />
        </>
      )}
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {builderUsersError?.message ?? rolesError?.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};
