import AddIcon from "@mui/icons-material/Add";
import { Box, Fab, Typography } from "@mui/material";

interface BuilderSelectorProps {
  title: string;
  createAction: () => void;
}

export const PageHeader = ({
  title,
  createAction = () => {},
}: BuilderSelectorProps) => {
  return (
    <Box sx={{ display: "flex", marginBottom: 2, padding: 2 }}>
      <Typography variant="h5">{title}</Typography>
      {/* CREATE BUTTON */}
      <Fab
        color="primary"
        onClick={() => createAction()}
        sx={{ marginLeft: "auto" }}
      >
        <AddIcon />
      </Fab>
    </Box>
  );
};
