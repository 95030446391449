import { getHttpClient } from "./client";

export interface FetchOptions {
  url: string;
  token: string;
  data?: object;
  method?: string;
  params?: object;
  options?: object;
}

export const apiRequest = async <T>({
  url,
  token,
  data,
  method,
  params,
  options,
}: FetchOptions) => {
  const client = await getHttpClient({
    baseURL: import.meta.env.VITE_DEV_API
      ? "https://api.dev.zusapi.com/data-pipeline-v2"
      : import.meta.env.VITE_BDP_API_URL,
    token,
    params,
    options,
  });
  return client
    .request<T>({
      method,
      url,
      data,
    })
    .then((res) => res.data);
};

export const getApi = async <T>({
  url,
  token,
  params,
  options,
}: FetchOptions) => {
  return apiRequest<T>({
    method: "get",
    url,
    token,
    params,
    options,
  });
};

export const postApi = <T>({
  url,
  data,
  token,
  params,
  options,
}: FetchOptions) => {
  return apiRequest<T>({
    method: "post",
    url,
    data,
    token,
    params,
    options,
  });
};

export const putApi = <T>({
  url,
  data,
  token,
  params,
  options,
}: FetchOptions) => {
  return apiRequest<T>({
    method: "put",
    url,
    data,
    token,
    params,
    options,
  });
};

export const patchApi = <T>({
  url,
  data,
  token,
  params,
  options,
}: FetchOptions) => {
  return apiRequest<T>({
    method: "patch",
    url,
    data,
    token,
    params,
    options,
  });
};

export const deleteApi = <T>({
  url,
  data,
  token,
  params,
  options,
}: FetchOptions) => {
  return apiRequest<T>({
    method: "delete",
    url,
    data,
    token,
    params,
    options,
  });
};
