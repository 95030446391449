import { Typography } from "@mui/material";
import "./ShipTitle.scss";

export const ShipTitle = () => {
  return (
    <>
      <img
        src="/logo/zus-logo.png"
        style={{ height: 35, marginRight: 5, marginTop: -12 }}
        alt="Zus Logo"
      />
      <Typography
        variant="h5"
        component="div"
        sx={{ flexGrow: 1, marginTop: 1 }}
      >
        S
        <Typography
          className="ship-header-small-letters"
          variant="overline"
          component="span"
        >
          ecure{" "}
        </Typography>
        H
        <Typography
          className="ship-header-small-letters"
          variant="overline"
          component="span"
        >
          ealthcare{" "}
        </Typography>
        I
        <Typography
          className="ship-header-small-letters"
          variant="overline"
          component="span"
        >
          nformation{" "}
        </Typography>
        P
        <Typography
          className="ship-header-small-letters"
          variant="overline"
          component="span"
        >
          ipeline
        </Typography>
      </Typography>
    </>
  );
};
