import { Box, Button, Typography } from "@mui/material";
import type { Organization } from "fhir/r4";
import { useState } from "react";
import { CommonwellChip } from "../../CommonwellChip";
import { CommonwellCreationForm } from "./CommonwellCreationForm";

interface CommonwellSectionProps {
  builderId: string;
  name: string;
  organization: Organization;
}

const COMMONWELL_IDENTIFIER_SYSTEM = "https://commonwellalliance.org/";

export const CommonwellSection = ({
  builderId,
  name,
  organization,
}: CommonwellSectionProps) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);
  const commonwellIdentifier = organization.identifier?.find(
    (identifier) => identifier.system === COMMONWELL_IDENTIFIER_SYSTEM
  );

  return (
    <Box sx={{ padding: "1em" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography sx={{ mr: "1em" }} variant="h6">
          Commonwell
        </Typography>
        <CommonwellChip
          color={commonwellIdentifier ? "success" : "warning"}
          label={
            commonwellIdentifier
              ? `Provisioned with OID: ${commonwellIdentifier.value}`
              : "Not provisioned"
          }
        />
        {!commonwellIdentifier && (
          <Button
            sx={{ ml: "1em" }}
            variant="outlined"
            color="primary"
            onClick={handleDialogOpen}
          >
            Provision now
          </Button>
        )}
      </Box>

      <CommonwellCreationForm
        open={openDialog}
        handleClose={handleDialogClose}
        builderId={builderId}
        name={name}
        odsOrganizationId={organization.id as string}
        location={organization.address?.[0]}
      />
    </Box>
  );
};
