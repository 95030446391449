import { useAuth0 } from "@auth0/auth0-react";
import {
  Alert,
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import Form from "@rjsf/material-ui/v5";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postApi } from "../../api";
import { BuilderSelector } from "../../components/BuilderSelector";
import { schemaConnectorList } from "../../forms";
import { SchemaConnector } from "../../forms/types";
import { Builder, Data } from "../../types";
import "./ConnectorsCreate.scss";

const steps = ["Select Builder", "Select Connector Type", "Add Connector Data"];

export const ConnectorsCreatePage = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const [builder, setBuilder] = useState<Data<Builder>>();
  const [connector, setConnector] = useState<SchemaConnector | undefined>();
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);

  const handleNext = () => {
    if (activeStep !== steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleChange = (event: SelectChangeEvent) => {
    const newConnector = schemaConnectorList.find(
      (c) => c.value === event.target.value
    );
    if (newConnector) setConnector(newConnector);
  };

  const handleSubmit = ({ formData: fd }: any) => {
    if (!builder || !connector || !fd) return;

    const { id: builderId } = builder;
    const { value: connectorType } = connector;
    const { Name: connectorName } = fd;

    const connectorArgs = Object.entries(fd)
      .filter(([key, _val]) => key !== "Name")
      .reduce((agg, [key, val]) => {
        return { ...agg, [key]: val };
      }, {});

    getAccessTokenSilently().then((token) =>
      postApi({
        url: "/connector",
        data: {
          builderId,
          connectorType,
          connectorName,
          connectorArgs,
        },
        token,
      }).then(() => {
        navigate("/connectors");
      })
    );
  };

  return (
    <Box sx={{ width: "100%", backgroundColor: "white", padding: 3 }}>
      {/* STEPPER */}
      <Stepper sx={{ marginBottom: 3 }} activeStep={activeStep}>
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <>
        <Box
          sx={{
            marginBottom: 2,
            padding: 2,
          }}
        >
          {/* STEP: SELECT BUILDER */}
          {activeStep === 0 && (
            <BuilderSelector builder={builder} setBuilder={setBuilder} />
          )}

          {/* STEP: SELECT CONNECTOR TYPE */}
          {activeStep === 1 && (
            <>
              <InputLabel id="connector-select-label">
                Choose Connector
              </InputLabel>
              <Select
                key="connector-select"
                sx={{ minWidth: 400 }}
                labelId="connector-select-label"
                id="connector-select"
                value={connector?.value || ""}
                onChange={handleChange}
              >
                {schemaConnectorList.map((schemaConnector) => {
                  return (
                    <MenuItem
                      key={schemaConnector?.value}
                      value={schemaConnector?.value || 10}
                    >
                      {schemaConnector?.viewValue}
                    </MenuItem>
                  );
                })}
              </Select>
            </>
          )}
          {/* CONNECTOR FORM EMPTY STATE */}
          {activeStep === 2 && (!connector || !builder) && (
            <Typography sx={{ mt: 2, mb: 1 }}>
              <i>Please go back and select a Connector and Builder</i>
            </Typography>
          )}
          {/* STEP: CONNECTOR FORM */}
          {activeStep === 2 && connector && builder && (
            <Form
              onSubmit={handleSubmit}
              className="form-wrapper"
              schema={connector?.schema || {}}
              uiSchema={connector?.uiSchema || {}}
            />
          )}
        </Box>
        {/* BACK / NEXT BUTTONS */}
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />

          <Button disabled={activeStep === 2} onClick={handleNext}>
            Next
          </Button>
        </Box>
      </>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSnackBar(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnackBar(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          Error Saving Connector
        </Alert>
      </Snackbar>
    </Box>
  );
};
