import { defaultJobComponent, defaultJobSchema } from "./schema_job_util";
import { SchemaJob } from "./types";

export const jobSaveSchema: SchemaJob = {
  ...defaultJobSchema,
  value: "Configure a Custom Job",
  jobStatusText: "Job has been saved",
  route: "save-job",
  buttonText: "Save Job",
  description: "",
  components: {
    ...defaultJobSchema.components,
    builderSelector: { ...defaultJobComponent, show: true },
    querySelector: { ...defaultJobComponent, show: true },
    ignoreErrors: { ...defaultJobComponent, show: true },
    jobNameInput: { ...defaultJobComponent, show: true },
    connectorSelector: { ...defaultJobComponent, show: true },
  },
};
