import { SchemaConnector } from "./types";

export const zusParquetConnectorSchema: SchemaConnector = {
  viewValue: "ZusParquet",
  value: "ZusParquetS3Connector",
  uiSchema: {
    "ui:submitButtonOptions": {
      submitText: "Create Connector",
      norender: false,
      props: {
        disabled: false,
        className: "btn btn-info",
      },
    },
    Name: { classNames: "connector-form-input" },
    zusBucket: { classNames: "connector-form-input" },
    zusPath: { classNames: "connector-form-input" },
  },
  schema: {
    title: "Zus Parquet Connector",
    type: "object",
    required: ["Name", "zusBucket", "zusPath"],
    properties: {
      Name: { type: "string", title: "Connector Name" },
      zusBucket: { type: "string", title: "Zus Bucket Name" },
      zusPath: { type: "string", title: "Zus Path to Output" },
    },
  },
};
