import { useAuth0 } from "@auth0/auth0-react";
import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { postApi } from "../api";
import { JobSelector } from "../components/SavedJobSelector";
import { ScheduleSelector } from "../components/ScheduleSelector";
import { defaultJobObject, defaultSelectedSchedule } from "../default-objects";
import { snackbarOpenPropBuilder, useGlobalSnackbar } from "../hooks";
import "../pages/JobCreate/JobCreate.scss";
import { JobObject, ScheduledJob } from "../types";

interface ScheduleFormProps {
  isEdit?: boolean;
  savedSchedule?: ScheduledJob;
  onSaveComplete?: () => void;
}

export const ScheduleForm = ({
  isEdit = false,
  savedSchedule,
  onSaveComplete = () => {},
}: ScheduleFormProps) => {
  const { getAccessTokenSilently } = useAuth0();

  // create job vars
  const [selectedJob, setSelectedJob] = useState<JobObject>(defaultJobObject);
  const [selectedSchedule, setSelectedSchedule] = useState<string>(
    defaultSelectedSchedule
  );

  // helper vars
  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [scheduleJob, setScheduleJob] = useState<number>(0);
  const [spinning, setSpinning] = useState<boolean>(false);
  const [snackbar, setSnackBar] = useGlobalSnackbar();

  const startJob = () => {
    setScheduleJob(scheduleJob + 1);
  };

  // SCHEDULE JOB
  useEffect(() => {
    if (!scheduleJob) return;

    getAccessTokenSilently()
      .then((token) => {
        setSpinning(true);
        return postApi({
          url: "/job/scheduled",
          data: {
            builderId: selectedJob?.builderId,
            jobName: selectedJob?.jobName,
            scheduleType: selectedSchedule,
          },
          token,
        });
      })
      .then(() => {
        setSpinning(false);
        setSnackBar({
          severity: "success",
          message: isEdit ? "Schedule Updated" : "Schedule Crated",
        });
        onSaveComplete();
      })
      .catch((err) => {
        setSpinning(false);

        setSnackBar(
          snackbarOpenPropBuilder({
            ...snackbar,
            severity: "error",
            message: `Error Creating Schedule: ${
              JSON.stringify(err?.message) || {}
            }`,
          })
        );
        onSaveComplete();
      });
  }, [scheduleJob]);

  // SCHEDULE JOB
  useEffect(() => {
    if (isEdit) {
      setSelectedSchedule(savedSchedule?.scheduleType || "");

      setSelectedJob({
        ...defaultJobObject,
        builderId: savedSchedule?.builderId || "",
        jobName: savedSchedule?.jobName || "",
      });
    }
  }, []);

  // Check if form valid Job
  useEffect(() => {
    const newFormIsValid =
      selectedJob?.builderId !== "" && selectedSchedule !== "";

    if (newFormIsValid !== undefined && newFormIsValid !== formIsValid) {
      setFormIsValid(newFormIsValid);
    }
  }, [selectedJob, selectedSchedule]);

  return (
    <>
      {/* LOADING SPINNER */}
      <Backdrop open={spinning}>
        <CircularProgress />
      </Backdrop>

      {/* SAVED JOBS LIST SELECTOR */}
      {!isEdit && (
        <Box className="page-component" sx={{ paddingTop: 1 }}>
          <JobSelector
            selectedJob={selectedJob}
            setSelectedJob={setSelectedJob}
          />
        </Box>
      )}

      {/* SCHEDULE SELECTOR */}
      <Box className="page-component" sx={{ paddingTop: 1 }}>
        <ScheduleSelector
          scheduleId={selectedSchedule}
          setSchedule={setSelectedSchedule}
        />
      </Box>

      {/* SCHEDULE JOB BUTTON */}
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button variant="contained" disabled={!formIsValid} onClick={startJob}>
          Schedule
        </Button>
        <Button
          sx={{ marginLeft: 2 }}
          variant="contained"
          onClick={() => onSaveComplete()}
        >
          Cancel
        </Button>
      </Box>
    </>
  );
};
