import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import type { BuilderUser, UserRole } from "../../../types";
import { UserForm } from "./UserForm";

interface UpdateUserDialogProps {
  builderId: string;
  roles: UserRole[];
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  exisitingUser: BuilderUser;
  setUser: React.Dispatch<React.SetStateAction<BuilderUser | null>>;
}

export const UpdateUserDialog = ({
  builderId,
  roles,
  exisitingUser,
  setUser,
  openDialog,
  setOpenDialog,
}: UpdateUserDialogProps) => {
  const onCloseDialog = () => {
    setUser(null);
    setOpenDialog(false);
  };
  return (
    <Dialog open={openDialog} onClose={onCloseDialog} fullWidth maxWidth="sm">
      <DialogTitle>Edit user</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <UserForm
          type="update"
          builderId={builderId}
          roles={roles}
          existingUser={exisitingUser}
          setOpenDialog={setOpenDialog}
        />
      </DialogContent>
    </Dialog>
  );
};
