import { useAuth0 } from "@auth0/auth0-react";
import CheckIcon from "@mui/icons-material/Check";
import CreateIcon from "@mui/icons-material/Create";
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { deleteApi, getApi } from "../api";
import { JobsCreateForm } from "../forms/JobCreateForm";
import { SavedJob, SnackbarProp } from "../types";
import { DeleteButtonWithConfirmation } from "./DeleteButtonWithConfirmation";

export const SavedJobViewer = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [jobs, setJobs] = useState<SavedJob[]>([]);
  const [editJob, setEditJob] = useState<SavedJob>();
  const [snackbar, setSnackBar] = useState<SnackbarProp>();
  const [sbOpen, setSbOpen] = useState(false);
  const [reload, setReload] = useState(0);
  const [editOpen, setEditOpen] = useState(false);

  const handleEdit = (job: SavedJob) => {
    setEditJob(job);
    setEditOpen(true);
  };
  const handleSbClose = () => {
    setSbOpen(false);
    setSnackBar(undefined);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleDelete = ({ builderId, jobName }: SavedJob) => {
    getAccessTokenSilently().then((token) => {
      deleteApi({
        url: "/job/saved",
        token,
        data: {
          builderId,
          jobName,
        },
      }).then(() => {
        setSnackBar({
          severity: "success",
          message: "Job Deleted!",
        });
        setSbOpen(true);
        setReload(reload + 1);
      });
    });
  };

  useEffect(() => {
    getAccessTokenSilently().then((token) =>
      getApi({
        url: "/job/saved",
        token,
      })
        .then((result: any) => result?.data)
        .then((fetchedJobs: any) => {
          setJobs(fetchedJobs);
        })
    );
  }, [reload]);

  useEffect(() => {
    if (snackbar) {
      setSbOpen(true);
      setReload(reload + 1);
    }
  }, [snackbar]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Job Name</TableCell>
              <TableCell>Builder</TableCell>
              <TableCell width={100} align="center">
                Export All Job
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.map((job) => (
              <TableRow hover key={`${job.builderId}/${job.jobName}`}>
                <TableCell>{job?.jobName}</TableCell>
                <TableCell>{job?.meta?.builderName}</TableCell>
                <TableCell align="center">
                  {job?.queries?.length === 0 && <CheckIcon />}
                </TableCell>
                <TableCell width={15}>
                  <IconButton aria-label="Edit" onClick={() => handleEdit(job)}>
                    <CreateIcon />
                  </IconButton>
                </TableCell>
                <TableCell width={15}>
                  <DeleteButtonWithConfirmation
                    handleDelete={() => handleDelete(job)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* EDIT MODAL */}
      <Dialog onClose={handleEditClose} open={editOpen} maxWidth="xl">
        <DialogTitle>{editJob?.jobName}</DialogTitle>
        <DialogContent>
          <JobsCreateForm
            isEdit
            savedJob={editJob}
            onSaveComplete={handleEditClose}
            setSnackBar={setSnackBar}
            onCancel={handleEditClose}
          />
        </DialogContent>
      </Dialog>

      {/* SNACKBAR */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={sbOpen}
        autoHideDuration={6000}
        onClose={handleSbClose}
      >
        <Alert
          variant="filled"
          onClose={handleSbClose}
          severity={snackbar?.severity}
          sx={{ width: "100%" }}
        >
          {snackbar?.message || "Missing Message"}
        </Alert>
      </Snackbar>
    </>
  );
};
