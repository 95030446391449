import { QueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Client from "fhir-kit-client";
import { FHIR_URL } from "./constants";

export const queryClient = new QueryClient();

export const fhirClient = new Client({
  baseUrl: FHIR_URL,
});

export const getHttpClient = async ({
  baseURL,
  token,
  options,
  params,
}: {
  baseURL: string;
  token: string;
  params?: object;
  options?: object;
}) => {
  try {
    return axios.create({
      baseURL,
      timeout: 20000,
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
        ...options,
      },
      params: {
        ...params,
      },
    });
  } catch (error) {
    return axios.create({
      baseURL,
      timeout: 50000,
      headers: {
        accept: "application/json",
      },
    });
  }
};

export const isVpnError = (err: AxiosError) => {
  switch (err.code) {
    case "ERR_NETWORK":
    case "ECONNABORTED":
      return true;
    default:
      return false;
  }
};
