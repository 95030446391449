import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, Dialog, DialogActions, Typography } from "@mui/material";
import { useNetworkErrorOpen } from "../hooks";

export const NetworkErrorDialog = () => {
  const [open, setOpen] = useNetworkErrorOpen();

  const handleClose = () => {
    window.location.reload();
  };

  return (
    <Dialog onClose={() => setOpen(false)} open={open}>
      <img src="error_page.png" alt="vpn icon" />
      <Typography sx={{ margin: 2 }} paragraph>
        If you’re seeing the screen, your network is working to some degree but
        for some reason you can’t connect to the API
      </Typography>
      <DialogActions sx={{ marginBottom: 1, marginRight: 1 }}>
        <Button
          variant="contained"
          onClick={handleClose}
          startIcon={<RefreshIcon />}
        >
          Reload
        </Button>
      </DialogActions>
    </Dialog>
  );
};
