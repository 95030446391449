import { useAuth0 } from "@auth0/auth0-react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import {
  format,
  formatDistanceStrict,
  formatDistanceToNowStrict,
  formatISO,
} from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getApi, isVpnError, postApi } from "../../api";
import { ModalSimple } from "../../components/ModalSimple";
import { PageHeader } from "../../components/PageHeader";
import { JobRunForm } from "../../forms/JobRunForm";
import {
  snackbarOpenPropBuilder,
  useGlobalSnackbar,
  useNetworkErrorOpen,
} from "../../hooks";
import { JobObject } from "../../types";
import "../JobsList/JobsList";
import "./JobRunList.scss";

interface JobListObject extends JobObject {
  builder: string;
  jobStartTimeDistance?: string;
  jobStatusIcon?: string;
  queryKeys: string[];
  link: string;
  jobDuration?: string;
}
interface RestartJob {
  jobId: string;
  builderId: string;
}

interface RestartJobResponse {
  jobId: string;
  builderId: string;
  jobConfig: JobObject;
}

const jobObj: JobListObject[] = [];

export const JobsRunsListPage = () => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const [jobs, setJobs] = useState(jobObj);

  const [restartJob, setRestartJob] = useState<RestartJob>();
  const [restartText, setRestartText] = useState<string>("");
  const [openRestartModal, setOpenRestartModal] = useState<boolean>(false);
  const [openRunModal, setOpenRunModal] = useState<boolean>(false);
  const [openNetworkError, setOpenNetworkError] = useNetworkErrorOpen();
  const [snackbar, setSnackBar] = useGlobalSnackbar();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const handleRestart = ({ builderId, jobId }: JobListObject) => {
    if (!jobId) return;

    setRestartJob({ builderId, jobId });
  };

  const handleCellClick = ({ link }: JobListObject) => navigate(link);
  const handleChangePage = (_event: unknown, newPage: number) =>
    setPage(newPage);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatJob = (job: JobListObject) => {
    const startTime = job?.startTime ? new Date(job?.startTime) : undefined;
    const endTime = job?.endTime ? new Date(job?.endTime) : undefined;

    return {
      ...job,
      builder: job?.meta?.builderName || job?.builderId,

      jobName: job?.meta?.jobName,
      link: `/jobs/${job.builderId}/${job.jobId}`,
      startTime: format(new Date(job?.startTime || 0), "MM/dd/yyyy h:mm aaa"),
      endTime: formatISO(new Date(job?.endTime || 0)),
      jobDuration:
        startTime && endTime
          ? formatDistanceStrict(startTime, endTime)
          : undefined,
      jobStartTimeDistance: startTime
        ? formatDistanceToNowStrict(startTime, {
            addSuffix: true,
          })
        : "Unknown",
    };
  };

  useEffect(() => {
    if (restartJob) {
      getAccessTokenSilently()
        .then((token) =>
          postApi({ url: "/job/restart", token, data: restartJob })
        )
        .then((jobResponse: any) => jobResponse?.data?.jobConfig || {})
        .then(({ jobConfig }: RestartJobResponse) => {
          const { jobId, builderId } = jobConfig || {};
          setRestartText("Job Started.");
          setOpenRestartModal(true);
          const navUrl =
            jobId && builderId ? `/jobs/${builderId}/${jobId}` : "/jobs";
          setTimeout(() => navigate(navUrl), 1500);
        })
        .catch((err) => {
          setRestartText(`Job Failed: ${JSON.stringify(err?.response || {})}`);
          setOpenRestartModal(true);
        });
    }
  }, [restartJob]);

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      getApi({ url: "/job", token })
        .then((x: any) => {
          const formattedJobs = (x?.data || []).map((job: JobListObject) =>
            formatJob(job)
          );

          setJobs(formattedJobs);
        })
        .catch((err: any) => {
          if (isVpnError(err)) {
            setOpenNetworkError(!openNetworkError || true);
          } else {
            setSnackBar(
              snackbarOpenPropBuilder({
                ...snackbar,
                severity: "error",
                message: err?.response?.data.message || err?.message,
              })
            );
          }
        });
    });
  }, []);

  return (
    <div style={{ height: "100%", width: "90%", backgroundColor: "white" }}>
      <PageHeader
        title="Job Runs"
        createAction={() => {
          setOpenRunModal(true);
        }}
      />
      <div style={{ flexGrow: 1, height: 1000 }}>
        {jobs && (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Builder</TableCell>
                <TableCell>Job Name</TableCell>
                <TableCell>Start</TableCell>
                <TableCell />
                <TableCell>Duration</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {/* start times are possibly undefined, those would be sorted lower */}
              {[...jobs]
                .sort((a, b) => (a.startTime || 0) - (b.startTime || 0))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((job) => (
                  <TableRow
                    key={job.jobId}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      sx={{ textTransform: "lowercase" }}
                      component="th"
                      scope="row"
                      className="linkCell"
                      onClick={() => handleCellClick(job)}
                    >
                      {job.jobStatus}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className="linkCell"
                      onClick={() => handleCellClick(job)}
                    >
                      <div className="textContainer">{job.jobId}</div>
                    </TableCell>
                    <TableCell
                      className="linkCell"
                      onClick={() => handleCellClick(job)}
                    >
                      {job.builder}
                    </TableCell>
                    <TableCell
                      className="linkCell"
                      onClick={() => handleCellClick(job)}
                    >
                      {job.jobName}
                    </TableCell>
                    <TableCell>{job.startTime}</TableCell>
                    <TableCell>{job.jobStartTimeDistance}</TableCell>
                    <TableCell>{job.jobDuration}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => handleRestart(job)}
                        aria-label="restart job"
                      >
                        <RestartAltIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={jobs.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
        {/* RESTART MODAL */}
        <ModalSimple
          key="modal"
          text={restartText}
          setOpen={setOpenRestartModal}
          open={openRestartModal}
        />
      </div>
      {/* CREATE JOB MODAL */}
      <Dialog onClose={() => setOpenRunModal(false)} open={openRunModal}>
        <DialogTitle>Start A Job</DialogTitle>
        <DialogContent>
          <JobRunForm onCancel={() => setOpenRunModal(false)} />
        </DialogContent>
      </Dialog>
    </div>
  );
};
