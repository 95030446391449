import { SchemaConnector } from "./types";

export const bigQueryConnectorSchema: SchemaConnector = {
  viewValue: "Big Query",
  value: "BigQueryConnector",
  uiSchema: {
    "ui:submitButtonOptions": {
      submitText: "Create Connector",
      norender: false,
      props: {
        disabled: false,
        className: "btn btn-info",
      },
    },
    Name: { classNames: "connector-form-input" },
    credentials: { classNames: "connector-form-input" },
    project: { classNames: "connector-form-input" },
    parentProject: { classNames: "connector-form-input" },
    dataset: { classNames: "connector-form-input" },
  },
  schema: {
    title: "Big Query Connector",
    type: "object",
    required: ["Name", "credentials", "project", "parentProject", "dataset"],
    properties: {
      Name: { type: "string", title: "Connector Name" },
      credentials: { type: "string", title: "JSON GCP credentials" },
      project: { type: "string", title: "GCP Project ID " },
      parentProject: { type: "string", title: "GCP Parent Project ID" },
      dataset: { type: "string", title: "Destination Data Set" },
    },
  },
};
