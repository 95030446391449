import { useAuth0 } from "@auth0/auth0-react";
import PersonIcon from "@mui/icons-material/Person";
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  ThemeProvider,
  Toolbar,
} from "@mui/material";
import React from "react";
import { appBarTheme } from "../style/theme";
import { ShipTitle } from "./ShipTitle";

export const AppToolbar = () => {
  const { logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <ThemeProvider theme={appBarTheme}>
      <AppBar position="static">
        <Toolbar>
          {/* LOGO AND NAME */}
          <ShipTitle />

          {/* USER MENU */}
          <IconButton
            id="toolbar-user-button"
            aria-controls={open ? "toolbar-user-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleMenuClick}
          >
            <PersonIcon />
          </IconButton>
          {/* USER MENU DROPDOWN */}
          <Menu
            id="toolbar-user-menu"
            aria-labelledby="toolbar-user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};
