import { Box, TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { CommonwellOrganizationFormProps } from "../types";

export const ContactDetailFormInput = ({
  index,
  control,
}: {
  index: number;
  control: Control<CommonwellOrganizationFormProps>;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Controller
          control={control}
          name={`technicalContacts.${index}.name`}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              label="Name"
              required
              sx={{ margin: 1, width: "70%" }}
            />
          )}
        />
        <Controller
          control={control}
          name={`technicalContacts.${index}.title`}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              label="Title"
              required
              sx={{ margin: 1, width: "30%" }}
            />
          )}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Controller
          control={control}
          name={`technicalContacts.${index}.email`}
          rules={{
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Entered value does not match email format",
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              label="Email"
              required
              sx={{ margin: 1, width: "65%" }}
            />
          )}
        />
        <Controller
          control={control}
          name={`technicalContacts.${index}.phone`}
          rules={{
            pattern: {
              value: /^(\(\d{3}\) |\d{3}-)\d{3}-\d{4}$/,
              message: "Entered value does not match phone number format",
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              label="Phone"
              required
              sx={{ margin: 1, width: "35%" }}
            />
          )}
        />
      </Box>
    </Box>
  );
};
