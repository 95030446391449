import { useAuth0 } from "@auth0/auth0-react";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import type { AxiosError } from "axios";
import type { FhirResource } from "fhir-kit-client";
import { cloneDeepWith, isEmpty, pickBy } from "lodash";
import { FocusEvent, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { createBuilder, createODSOrganization } from "../../api";
import { queryClient } from "../../api/client";
import { statesOfAmerica } from "../../forms/types";
import type { Builder, Data } from "../../types";
import { BuilderCreationDialog } from "./BuilderCreationDialog";
import type { OdsOrganizationProps } from "./types";

interface ODSOrganizationCreationProps {
  odsData: OdsOrganizationProps;
  builderId: string;
}
interface OrganizationAddress {
  addressLineOne?: string;
  addressLineTwo?: string;
  city: string;
  state: string;
  postalCode: string;
}
interface BuilderFormProps {
  name: string;
  namespace: string;
  address?: OrganizationAddress;
}

export const BuilderCreationForm = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [createdBuilderId, setCreatedBuilderId] = useState<
    string | undefined
  >();
  const [createdOrganizationId, setCreatedOrganizationId] = useState<
    string | undefined
  >();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<BuilderFormProps>({
    defaultValues: {
      name: "",
      namespace: "",
      address: {
        addressLineOne: "",
        addressLineTwo: "",
        city: "",
        state: "",
        postalCode: "",
      },
    },
  });

  const {
    mutate: createOrganization,
    status: organizationCreationStatus,
    error: organizationCreationError,
  } = useMutation<FhirResource, Error, ODSOrganizationCreationProps>(
    async ({ odsData, builderId }: ODSOrganizationCreationProps) => {
      const token = await getAccessTokenSilently();
      return createODSOrganization({ token, body: odsData, builderId });
    },
    {
      onSuccess: (response: FhirResource) => {
        setCreatedOrganizationId(response.id);
        reset();
      },
    }
  );

  const handleOrganizationCreation = async (
    data: BuilderFormProps,
    builderId: string
  ) => {
    const sanitizedAddressValues = pickBy(data.address) as OrganizationAddress;
    let sanitizedData;
    if (isEmpty(sanitizedAddressValues)) {
      sanitizedData = { name: data.name };
    } else {
      const { addressLineOne, addressLineTwo, ...addressWithoutLines } =
        sanitizedAddressValues;
      let line;
      if (!isEmpty(addressLineOne) || !isEmpty(addressLineTwo)) {
        line = [addressLineOne, addressLineTwo].filter(Boolean) as string[];
        sanitizedData = {
          name: data.name,
          address: [
            {
              ...addressWithoutLines,
              line,
            },
          ],
        };
      } else {
        sanitizedData = {
          name: data.name,
          address: [sanitizedAddressValues],
        };
      }
    }
    createOrganization({
      odsData: sanitizedData,
      builderId,
    });
  };

  const {
    mutate: createAuthBuilder,
    status: authCreationStatus,
    error: authCreationError,
  } = useMutation<Data<Builder>, Error, BuilderFormProps>(
    async (builderData: { name: string; namespace: string }) => {
      const token = await getAccessTokenSilently();
      setOpenDialog(true);
      return createBuilder({ token, body: builderData });
    },
    {
      onSuccess: (response: Data<Builder>, data) => {
        setCreatedBuilderId(response.id);
        queryClient.invalidateQueries(["builders"]);
        handleOrganizationCreation(data, response.id);
      },
      onError: (error: any) => {
        const err = error as AxiosError;
        if (err.response?.status === 409) {
          setError("namespace", {
            type: "manual",
            message: "Namespace already exists",
          });
        }
      },
    }
  );

  const preSubmit = () => {
    const trimmedFormValues = cloneDeepWith(getValues(), (p) =>
      typeof p === "string" ? p.trim() : undefined
    );
    createAuthBuilder(trimmedFormValues);
  };

  const handleChangeName = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (getValues("namespace") === "" && event.target.value !== "") {
      setValue("namespace", `com.${event.target.value}`);
    }
  };

  return (
    <form onSubmit={handleSubmit(preSubmit)}>
      <BuilderCreationDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        authProgress={{
          state: authCreationStatus,
          entityId: createdBuilderId,
          errorMessage: authCreationError?.message,
        }}
        odsProgress={{
          state: organizationCreationStatus,
          entityId: createdOrganizationId,
          errorMessage: organizationCreationError?.message,
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignContent: "center",
        }}
      >
        <Controller
          name="name"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              label="Builder name"
              required
              sx={{ width: "100%", margin: 1 }}
              onBlur={(e) => {
                handleChangeName(e);
              }}
            />
          )}
        />
        <Controller
          name="namespace"
          control={control}
          rules={{ required: true }}
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...field}
              inputRef={ref}
              variant="outlined"
              label="Builder namespace"
              error={!!errors.namespace}
              helperText={
                errors.namespace
                  ? errors.namespace.message
                  : "(reverse domain notation e.g. com.zushealth)"
              }
              required
              sx={{ width: "100%", margin: 1 }}
              onChange={(_e) => {
                setValue("namespace", _e.target.value);
                clearErrors("namespace");
              }}
            />
          )}
        />
        <Box sx={{ width: "100%" }}>
          <Controller
            name="address.addressLineOne"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                label="Address Line 1"
                sx={{ width: "100%", margin: 1 }}
              />
            )}
          />
          <Controller
            name="address.addressLineTwo"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                label="Address Line 2"
                sx={{ width: "100%", margin: 1 }}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Controller
            name="address.city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                label="City"
                sx={{ width: "100%", margin: 1 }}
              />
            )}
          />
          <Controller
            name="address.state"
            control={control}
            render={({ field: { ref, onChange, ...field } }) => (
              <Autocomplete
                options={statesOfAmerica.map((state: string) => {
                  return { value: state, label: state };
                })}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(_, data) => onChange(data?.value)}
                disablePortal
                sx={{ width: "25%", margin: 1 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...field}
                    inputRef={ref}
                    variant="outlined"
                    label="State"
                  />
                )}
              />
            )}
          />
        </Box>
        <Controller
          name="address.postalCode"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              label="Zip Code"
              sx={{ margin: 1 }}
              inputProps={{
                pattern: "[0-9]{5}(-[0-9]{4})?",
              }}
            />
          )}
        />

        <Box sx={{ display: "flex", justifyContent: "flex-end", margin: 1 }}>
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Box>
      </Box>
    </form>
  );
};
