import { Alert, Snackbar } from "@mui/material";
import { defaultSnackbar } from "../default-objects";
import { useGlobalSnackbar } from "../hooks";

/*
  Global SnackBar: adds use of the snackbar without having to add a snackbar to each component
  
  Example: 
  // imports: 
  import { useOpenAxiosErrorSnackBar, useOpenSnackBar } from "../hooks";
  // at top of function: 
  const [setErrorSnackBar] = useOpenAxiosErrorSnackBar(undefined);
  const [setSnackBar] = useOpenSnackBar({ open: false });
  
  // in use effect
  setSnackBar({
    severity: "success",
    message: "It Worked",
  })
  /// in catch from a request 
  .catch(e: AxiosError) => {
    setErrorSnackBar(e)
  }
    
*/
export const GlobalSnackbar = () => {
  const [snackbar, setSnackBar] = useGlobalSnackbar();

  const handleSbClose = () => {
    setSnackBar({ ...defaultSnackbar, open: false });
  };

  return (
    <Snackbar
      sx={snackbar?.snackbarSx || defaultSnackbar?.snackbarSx}
      anchorOrigin={snackbar?.anchorOrigin || defaultSnackbar?.anchorOrigin}
      open={snackbar?.open || false}
      autoHideDuration={
        snackbar?.autoHideDuration || defaultSnackbar?.autoHideDuration
      }
      onClose={handleSbClose}
    >
      <Alert
        sx={snackbar?.alertSx || defaultSnackbar?.alertSx}
        variant={snackbar?.alertVariant || defaultSnackbar?.alertVariant}
        onClose={handleSbClose}
        severity={snackbar?.severity || defaultSnackbar?.severity}
      >
        {snackbar?.message || defaultSnackbar?.message}
      </Alert>
    </Snackbar>
  );
};
