import { defaultJobComponent, defaultJobSchema } from "./schema_job_util";
import { SchemaJob } from "./types";

export const JobCreateScheduleSchema: SchemaJob = {
  ...defaultJobSchema,
  value: "Schedule a Job",
  route: "schedule-job",
  jobStatusText: "Job has been Scheduled",
  buttonText: "Schedule Job",
  description: "",
  components: {
    ...defaultJobSchema.components,
    scheduleSelector: { ...defaultJobComponent, show: true },
    savedJobsList: {
      ...defaultJobComponent,
      show: true,
    },
  },
};
