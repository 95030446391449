import { useAuth0 } from "@auth0/auth0-react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { createUser, updateUser } from "../../../api/auth/users";
import { queryClient } from "../../../api/client";
import type { BuilderUser, Data, User, UserRole } from "../../../types";
import { SubmissionProgressInfo } from "../SubmissionProgressInfo";

interface UserFormData {
  name: string;
  email: string;
  roleId: string;
  verificationEmail: boolean;
  passwordResetEmail: boolean;
}

interface UserFormProps {
  type: "create" | "update";
  builderId: string;
  roles: UserRole[];
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  existingUser?: BuilderUser;
}

export const UserForm = ({
  type,
  builderId,
  roles,
  setOpenDialog,
  existingUser,
}: UserFormProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [createdUserId, setCreatedUserId] = useState<string | undefined>();
  const { handleSubmit, control } = useForm<UserFormData>({
    defaultValues: {
      name: existingUser?.name ?? "",
      email: existingUser?.email ?? "",
      roleId:
        existingUser?.role ??
        roles.find((role) => role.name === "Read Builders")?.id ??
        "",
      verificationEmail: false,
      passwordResetEmail: true,
    },
  });

  const handleCloseDialog = () => setOpenDialog(false);

  const { mutate, isIdle, status, error } = useMutation<
    Data<User>,
    Error,
    UserFormData
  >(
    async (userData: UserFormData) => {
      const token = await getAccessTokenSilently();
      if (type === "create") {
        return createUser({ token, body: { ...userData, builderId } });
      }
      return updateUser({
        token,
        userId: existingUser?.id as string,
        body: {
          ...userData,
          builderId,
        },
      });
    },
    {
      onSuccess: (response: Data<User>) => {
        setCreatedUserId(response.id);
        queryClient.invalidateQueries(["builderUsers", builderId]);
      },
    }
  );

  const onSubmit = async (data: UserFormData) => mutate(data);

  return isIdle ? (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Name"
              required
              sx={{ width: "70%", margin: 1 }}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          rules={{
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Entered value does not match email format",
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Email"
              required
              sx={{ width: "70%", margin: 1 }}
            />
          )}
        />
        <Controller
          name="roleId"
          control={control}
          render={({ field: { ref, onChange, ...field } }) => (
            <Autocomplete
              value={field.value}
              options={roles.map((role) => role.id)}
              getOptionLabel={(option) =>
                roles.find((role) => role.id === option)?.name ?? option
              }
              onChange={(_, data) => onChange(data)}
              disablePortal
              sx={{ width: "70%", margin: 1 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...field}
                  inputRef={ref}
                  variant="outlined"
                  label="Role"
                  required
                />
              )}
            />
          )}
        />
        {type === "create" && (
          <FormGroup sx={{ width: "70%" }}>
            <FormControlLabel
              control={
                <Controller
                  name="verificationEmail"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <Checkbox {...field} checked={value} onChange={onChange} />
                  )}
                />
              }
              label="Send verification email"
            />
            <FormControlLabel
              control={
                <Controller
                  name="passwordResetEmail"
                  control={control}
                  render={({ field: { value, onChange, ...field } }) => (
                    <Checkbox {...field} checked={value} onChange={onChange} />
                  )}
                />
              }
              label="Send password reset email"
            />
          </FormGroup>
        )}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", mt: "1em" }}>
        <Button variant="outlined" onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button variant="outlined" type="submit">
          {type === "create" ? "Create" : "Update"}
        </Button>
      </Box>
    </form>
  ) : (
    <>
      <SubmissionProgressInfo
        entity="User"
        state={status}
        type={type}
        entityId={existingUser?.id ?? createdUserId}
        errorMessage={error?.message}
      />
      {status !== "loading" && (
        <Button sx={{ alignSelf: "flex-end" }} onClick={handleCloseDialog}>
          Confirm
        </Button>
      )}
    </>
  );
};
