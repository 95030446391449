import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { getApi } from "./api";
import "./App.scss";
import { GlobalSnackbar } from "./components/GlobalSnackBar";
import { NetworkErrorDialog } from "./components/NetworkErrorDialog";
import { useNetworkErrorOpen } from "./hooks";
import { AppDrawer } from "./nav/AppDrawer";
import { AppToolbar } from "./nav/AppToolbar";
import { BuilderCreate } from "./pages/BuilderCreate";
import { BuilderDetails } from "./pages/BuilderDetails";
import { BuilderList } from "./pages/BuilderList";
import { ConnectorsPage } from "./pages/Connectors/Connectors";
import { ConnectorsCreatePage } from "./pages/ConnectorsCreate/ConnectorsCreate";
import { JobsCreatePage } from "./pages/JobCreate/JobCreate";
import { JobRunCreatePage } from "./pages/JobRunCreate/JobRunCreate";
import { JobRunDetailPage } from "./pages/JobRunDetail";
import { JobsRunsListPage } from "./pages/JobRunList/JobRunList";
import { JobsListPage } from "./pages/JobsList/JobsList";
import { QueriesPage } from "./pages/Queries";
import { QueriesCreatePage } from "./pages/QueriesCreate";
import { SchedulePage } from "./pages/Schedule";
import { ScheduleList } from "./pages/ScheduleList";
import { SnowflakeAccountDetail } from "./pages/SnowflakeAccountDetail";
import { SnowflakeAccountManager } from "./pages/SnowflakeAccountManager";
import { SubscriptionsList } from "./pages/SubscriptionsList";

export const App = () => {
  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0();
  const [openNetworkError, setOpenNetworkError] = useNetworkErrorOpen();

  useEffect(() => {
    getApi({ url: "/status", token: "" }).catch(() => {
      setOpenNetworkError(!openNetworkError || true);
    });
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <div>Oops... {error.message}</div>
      </Box>
    );
  }
  if (isAuthenticated) {
    return (
      <div className="App">
        <AppToolbar />
        <div className="app-page">
          <AppDrawer />
          <Box className="main-section">
            <Routes>
              <Route path="/" element={<Navigate to="/runs" />} />
              <Route path="runs" element={<JobsRunsListPage />} />
              <Route path="runs/create" element={<JobRunCreatePage />} />
              <Route path="jobs" element={<JobsListPage />} />
              <Route path="jobs/create" element={<JobsCreatePage />} />
              <Route
                path="jobs/:builderId/:jobId"
                element={<JobRunDetailPage />}
              />
              <Route path="connectors" element={<ConnectorsPage />} />
              <Route
                path="connectors/create"
                element={<ConnectorsCreatePage />}
              />
              <Route path="queries" element={<QueriesPage />} />
              <Route path="queries/create" element={<QueriesCreatePage />} />
              <Route path="schedule" element={<ScheduleList />} />
              <Route path="schedule/create" element={<SchedulePage />} />

              <Route
                path="builder_admin/snowflake_accounts"
                element={<SnowflakeAccountManager />}
              />
              <Route
                path="builder_admin/snowflake_accounts/:account_identifier"
                element={<SnowflakeAccountDetail />}
              />
              <Route
                path="subscriptions/list"
                element={<SubscriptionsList />}
              />

              <Route path="builders" element={<BuilderList />} />
              <Route path="builders/:builderId" element={<BuilderDetails />} />
              <Route path="builders/create" element={<BuilderCreate />} />

              <Route path="*" element={<Navigate to="/jobs" />} />
            </Routes>
            <GlobalSnackbar />
            <NetworkErrorDialog />
          </Box>
        </div>
      </div>
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Button
        className="login-button"
        variant="contained"
        size="large"
        onClick={() => loginWithRedirect()}
      >
        Log in
      </Button>
    </Box>
  );
};
