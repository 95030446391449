import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  SubmissionProgress,
  SubmissionProgressInfo,
} from "./SubmissionProgressInfo";

interface BuilderCreationDialogProps {
  open: boolean;
  handleClose: () => void;
  authProgress: {
    state: SubmissionProgress["state"];
    entityId?: SubmissionProgress["entityId"];
    errorMessage?: string;
  };
  odsProgress: {
    state: SubmissionProgress["state"];
    entityId?: SubmissionProgress["entityId"];
    errorMessage?: string;
  };
}

export const BuilderCreationDialog = ({
  open,
  handleClose,
  authProgress,
  odsProgress,
}: BuilderCreationDialogProps) => {
  const navigate = useNavigate();
  const navigateBuilderList = () => navigate("/builders");
  const navigateBuilderDetails = () =>
    navigate(`/builders/${authProgress.entityId}`);

  const submissionEnded =
    odsProgress.state === "error" || odsProgress.state === "success";
  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle>Creating builder</DialogTitle>
      <List sx={{ display: "flex", flexDirection: "column", ml: 1, mr: 1 }}>
        <ListItem alignItems="center">
          <SubmissionProgressInfo
            type="create"
            state={authProgress.state}
            entity="Builder"
            entityId={authProgress.entityId}
            errorMessage={authProgress.errorMessage}
          />
        </ListItem>
        <ListItem alignItems="center">
          {authProgress.state === "success" && (
            <SubmissionProgressInfo
              type="create"
              state={odsProgress.state}
              entity="Organization"
              entityId={odsProgress.entityId}
              errorMessage={odsProgress.errorMessage}
            />
          )}
        </ListItem>
      </List>
      {submissionEnded && (
        <DialogActions sx={{ justifyContent: "space-between" }}>
          {odsProgress.state !== "error" && (
            <Box>
              <Button onClick={navigateBuilderList}>Go back to list</Button>
              <Button onClick={navigateBuilderDetails}>Edit builder</Button>
            </Box>
          )}
          <Button sx={{ alignSelf: "flex-end" }} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
