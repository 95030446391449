import { Autocomplete, Box, TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { statesOfAmerica } from "../../../forms/types";
import type { CommonwellOrganizationFormProps } from "../types";

export const LocationFormInput = ({
  index,
  control,
}: {
  index: number;
  control: Control<CommonwellOrganizationFormProps>;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Controller
          control={control}
          name={`locations.${index}.address1`}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              label="Address"
              required
              sx={{ width: "70%", margin: 1 }}
            />
          )}
        />
        <Controller
          control={control}
          name={`locations.${index}.postalCode`}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              label="Zip code"
              required
              sx={{ width: "30%", margin: 1 }}
              inputProps={{
                pattern: "[0-9]{5}(-[0-9]{4})?",
              }}
            />
          )}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Controller
          control={control}
          name={`locations.${index}.city`}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              label="City"
              required
              sx={{ width: "65%", margin: 1 }}
            />
          )}
        />
        <Controller
          control={control}
          name={`locations.${index}.state`}
          render={({ field: { ref, onChange, ...field } }) => (
            <Autocomplete
              value={field.value === "" ? null : field.value}
              options={statesOfAmerica.map((state: string) => state)}
              onChange={(_, data) => onChange(data)}
              disablePortal
              sx={{ margin: 1, width: "35%" }}
              ListboxProps={{ style: { maxHeight: "15rem" } }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...field}
                  inputRef={ref}
                  variant="outlined"
                  label="State"
                  required
                />
              )}
            />
          )}
        />
      </Box>
    </Box>
  );
};
