import { createTheme } from "@mui/material";

const baseTypography = {
  typography: {
    h1: {
      fontSize: "1.4rem",
      fontWeight: 400,
      letterSpacing: 0.2,
    },
    h2: {
      fontSize: "1.2rem",
      fontWeight: 400,
    },
  },
};

export const theme = createTheme({
  ...baseTypography,
  zIndex: {
    appBar: 1201, // does not fix my issue with app bar but shows how to fix zIndex globally so keeping
  },
  palette: {
    primary: {
      main: "#00677f",
      dark: "#001014",
      light: "#f4fbf4",
    },
    secondary: {
      main: "#00677f",
      dark: "#001014",
      light: "#f2fbfd",
    },
  },
  // sidebar: { // node working
  //   width: 200,
  //   closedWidth: 0,
  // },
});

// This is an interesting hack that solves two problems:
// 1. The MUI autocomplete automatically sets some colors to exactly black or white based entirely on whether it's a dark or light
//    palette in use.
// 2. The title is using the primary color for links, so we can override that here.
export const appBarTheme = createTheme({
  ...baseTypography,
  palette: {
    primary: {
      // The primary color is used for coloring links in the title, but otherwise not used in the AppBar
      main: "#1e88e5",
    },
    secondary: {
      main: "#00677f",
      dark: "#001014",
      light: "#f2fbfd",
      contrastText: "#ffffff",
    },
  },
  zIndex: {
    appBar: 1201, // does not fix my issue with app bar but shows how to fix zIndex globally so keeping
  },
});
