import { useEffect, useState } from "react";
import { defaultSnackbar } from "../../default-objects";
import { SnackbarProp } from "../../types";
import { useGlobalSnackbar } from "./useGlobalSnackbar";

export const useOpenSnackBar = (initSb: SnackbarProp | undefined) => {
  const [, setGSnackBar] = useGlobalSnackbar();
  const [snackbar, setSnackBar] = useState<SnackbarProp>(() => {
    return !initSb ? { open: false } : initSb;
  });

  useEffect(() => {
    setGSnackBar({ ...defaultSnackbar, ...snackbar, open: true });
  }, [snackbar]);

  return [setSnackBar] as const;
};
