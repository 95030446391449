import { DeleteOutline, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Control, useFieldArray } from "react-hook-form";
import type {
  CommonwellOrganizationFormProps,
  Location,
  TechnicalContacts,
} from "../types";
import { ContactDetailFormInput } from "./ContactDetailFormInput";
import { LocationFormInput } from "./LocationFormInput";

interface ArrayFormInputProps {
  control: Control<CommonwellOrganizationFormProps>;
  nameToDisplay: string;
  name: "locations" | "technicalContacts";
}

export const ArrayFormInput = ({
  control,
  nameToDisplay,
  name,
}: ArrayFormInputProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const handleAddCustom = () => {
    append(
      Object.keys(fields[0])
        .filter((fieldName) => fieldName !== "id" && fieldName !== "country")
        .reduce((a, v) => ({ ...a, [v]: "" }), {}) as
        | Location
        | TechnicalContacts
    );
  };

  return (
    <Accordion>
      <AccordionSummary
        sx={{
          ".MuiAccordionSummary-content": { alignItems: "center" },
        }}
        expandIcon={<ExpandMore />}
      >
        <Typography variant="h6" sx={{ width: "60%" }}>
          {nameToDisplay}
        </Typography>
        <Typography sx={{ color: "text.secondary" }}>
          Provided {fields.length} {`${nameToDisplay.toLocaleLowerCase()}(s)`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {fields.map((field, index) => (
            <Stack key={field.id}>
              {fields.length > 1 && (
                <IconButton
                  sx={{ alignSelf: "flex-end" }}
                  onClick={() => remove(index)}
                  color="error"
                >
                  <DeleteOutline />
                </IconButton>
              )}
              {name === "locations" ? (
                <LocationFormInput control={control} index={index} />
              ) : (
                <ContactDetailFormInput control={control} index={index} />
              )}
              <Divider
                sx={{
                  borderBottomWidth: "0.2em",
                  margin: "0.5em 0",
                }}
              />
            </Stack>
          ))}
          <Button
            variant="outlined"
            sx={{ marginTop: "0.5em" }}
            onClick={handleAddCustom}
          >
            Add
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
