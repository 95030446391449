import { useAuth0 } from "@auth0/auth0-react";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getApi } from "../api";
import { Builder, Connector, Data } from "../types";

interface ConnectorSelectorProps {
  setSelectedConnectors: (connectors: Connector[]) => void;
  selectedConnectors: Connector[];
  builder: Data<Builder> | undefined;
  disabled?: boolean;
}

export const ConnectorSelector = ({
  setSelectedConnectors,
  selectedConnectors,
  builder,
  disabled = false,
}: ConnectorSelectorProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const [connectors, setConnectors] = useState<Connector[]>([]);
  const [builderHasNone, setBuilderHasNone] = useState(false);

  useEffect(() => {
    if (builder?.id) {
      setConnectors([]); // clear out when builder changed
      setBuilderHasNone(false);
      getAccessTokenSilently().then((token) =>
        getApi({
          url: `/connector/${builder.id}`,
          token,
        })
          .then((cs: any) => cs?.data)
          .then((cs: Connector[]) => {
            const newConnectors = cs.filter((c: Connector) => !c.error);
            if (newConnectors?.length === 0) {
              setBuilderHasNone(true);
            }
            setConnectors(newConnectors);
          })
      );
    }
  }, [builder]);

  const handleToggle = (connector: Connector) => {
    const isSelected = selectedConnectors?.some(
      (selectedConnector: Connector) =>
        selectedConnector.connectorName === connector.connectorName &&
        selectedConnector.builderId === connector.builderId
    );
    let newSelectedConnectors = [];
    if (isSelected) {
      newSelectedConnectors = (selectedConnectors || []).filter(
        (selectedConnector: Connector) =>
          selectedConnector.connectorName !== connector.connectorName &&
          selectedConnector.builderId !== connector.builderId
      );
    } else {
      newSelectedConnectors = [...(selectedConnectors || []), connector];
    }
    setSelectedConnectors(newSelectedConnectors);
  };

  if (connectors.length === 0 && builderHasNone) {
    return (
      <>
        <div>
          <i>Builder has no connectors!</i>
        </div>

        <Button
          onClick={() => navigate("/connectors/create")}
          sx={{ marginTop: 2 }}
          variant="outlined"
          startIcon={<AddIcon />}
        >
          Create Connector
        </Button>
      </>
    );
  }
  if (connectors.length === 0) {
    return <i>Select a builder to populate Connectors</i>;
  }

  return (
    <List sx={{ bgcolor: "background.paper" }}>
      {connectors.map((connector: Connector) => {
        return (
          <ListItem
            disabled={disabled}
            key={`${connector.builderId}-${connector.connectorName}`}
            disablePadding
          >
            <ListItemButton onClick={() => handleToggle(connector)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedConnectors?.some(
                    (x) => x.connectorName === connector.connectorName
                  )}
                  inputProps={{ "aria-labelledby": connector.connectorName }}
                />
              </ListItemIcon>
              <ListItemText
                id={connector.connectorName}
                primary={connector.connectorName}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};
