import { useAuth0 } from "@auth0/auth0-react";
import { Info } from "@mui/icons-material";
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import type { FhirResource } from "fhir-kit-client";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { getBuilder, getOrganizations } from "../api";
import { OperationsSection } from "../components/BuilderProvisioning/OperationsSection";
import { UserList } from "../components/BuilderProvisioning/users/UserList";
import type { Builder, Data } from "../types";

const cardStyle = {
  minWidth: 275,
  marginTop: 2,
  marginBottom: 2,
  paddingLeft: 1,
  paddingRight: 1,
};

export const BuilderDetails = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const location = useLocation();
  const builderId = location.pathname.split("/")[2];

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    setOpenSnackbar(false);
  };

  const {
    isLoading: isBuilderLoading,
    data: builder,
    error: builderError,
  }: UseQueryResult<Data<Builder>, Error> = useQuery<Data<Builder>, Error>(
    ["builder", builderId],
    async () => {
      const token = await getAccessTokenSilently();
      return getBuilder({ token, builderId });
    }
  );

  const {
    isLoading: isOrganizationLoading,
    data: organizations,
    error: organizationError,
  }: UseQueryResult<
    FhirResource | (FhirResource & { type: "searchset" }),
    Error
  > = useQuery<
    | FhirResource
    | ((FhirResource & { type: "searchset" }) & { id: string; meta: object }),
    Error
  >(
    ["organization", builderId],
    async () => {
      const token = await getAccessTokenSilently();
      return getOrganizations({
        token,
        params: {
          identifier: `https://zusapi.com/fhir|${builderId}`,
        },
      });
    },
    {
      onError: () => setOpenSnackbar(true),
    }
  );
  const isLoading = isBuilderLoading || isOrganizationLoading;
  const organization = organizations?.entry?.at(0)?.resource;
  return (
    <Box sx={{ width: "940px", minWidth: "280px" }}>
      <Card elevation={3} sx={cardStyle}>
        <CardContent sx={{ maxWidth: "95%", margin: "auto" }}>
          {!isLoading ? (
            <>
              <CardHeader title={builder?.attributes.name} />
              {builder && organization ? (
                <OperationsSection
                  builderId={builderId}
                  name={builder?.attributes.name}
                  organization={organization}
                />
              ) : (
                <Chip
                  sx={{ fontSize: "1em", fontWeight: "bold" }}
                  icon={<Info />}
                  label="Organization for this builder does not exist"
                  variant="outlined"
                  color="error"
                  size="medium"
                />
              )}
              <UserList builderId={builderId} />
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" mr={5}>
                Loading builder...
              </Typography>
              <CircularProgress size={50} />
            </Box>
          )}
        </CardContent>
      </Card>
      <Snackbar
        open={openSnackbar}
        onClose={handleClose}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {builderError?.message || organizationError?.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
