import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../components/PageHeader";
import { ScheduleViewer } from "../components/ScheduleViewer";

export const ScheduleList = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ marginBottom: 10, backgroundColor: "white", width: "90%" }}>
      {/* Schedule JOB BUTTON */}

      {/* VIEW CARD */}
      <PageHeader
        title="Scheduled Jobs"
        createAction={() => {
          navigate("/schedule/create");
        }}
      />
      <ScheduleViewer />
    </Box>
  );
};
